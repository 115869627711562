import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Box, Button, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { green, greenDark, greyDark } from "../../lib/colors";
import { fetchAllOutletsInfo } from "../../functions/allOutletsInfo";
// import OrganizationModal from "../organizationModal";
import { fetchOrganizationInfo } from "../../functions/organization";
import AddIcon from "@mui/icons-material/Add";

export default function OrganizationsList() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [outlets, setOutlets] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orgID = localStorage.getItem("orgID");

  const registerNewOrganization = () => {
    navigate("/organizations/register");
  };

  useEffect(() => {
    if (waiting) {
      return;
    }
    if (!user) navigate("/login");
  }, [user, waiting]);

  useEffect(() => {
    // Some organizations are not shown here because of how you are looking for the information. Since new organization have no outlets assigned, they are not shown here
    const getOrganizations = async () => {
      setLoading(true);
      const outletsData = await fetchAllOutletsInfo();

      let orgNames = outletsData.map((obj) => obj.orgName);
      let uniqueOrgs = new Set(orgNames);
      uniqueOrgs = [...uniqueOrgs].sort();
      const totalOrgData = [];
      for (let org in uniqueOrgs) {
        let orgHelperData = {};
        let orgOutlets = outletsData.filter(
          (obj) => obj.orgName === uniqueOrgs[org]
        );
        orgHelperData["name"] = uniqueOrgs[org];
        orgHelperData["total"] = orgOutlets.length;
        let connected = 0;
        let disconnected = 0;
        let notActivated = 0;
        let activeSchedule = 0;
        for (let outlet in orgOutlets) {
          let wifiStatus = orgOutlets[outlet].wifiStatus;
          if (wifiStatus === "Connected") {
            connected += 1;
          } else if (wifiStatus === "Disconnected") {
            disconnected += 1;
          } else {
            notActivated += 1;
          }
          let scheduleStatus = orgOutlets[outlet].scheduleStatus;
          if (scheduleStatus === "Active") {
            activeSchedule += 1;
          }
        }
        orgHelperData["connected"] = connected;
        orgHelperData["disconnected"] = disconnected;
        orgHelperData["notActivated"] = notActivated;
        orgHelperData["active"] = activeSchedule;
        totalOrgData.push(orgHelperData);
      }

      setOutlets(outletsData);
      setOrgData(totalOrgData);
      setLoading(false);
    };

    getOrganizations();
  }, []);

  useEffect(() => {
    fetchOrganizationInfo(setOrganizations);
  }, []);

  const handleRowClick = (org) => {
    setSelectedOrg(org);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedOrg(null);
  };

  return (
    <View>
      <Box
        sx={{
          width: "950px",
          margin: "0 auto",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {orgData.map((org) => (
          <div
            onClick={() => handleRowClick(org)}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              alignSelf: "center",
              width: "100%",
              marginBottom: "5px",
              height: "65px",
              borderRadius: "10px",
              border: "1px solid #e0e0e0",
              boxShadow: "0px 2px 4px rgba(112, 112, 112, 0.2)",
              cursor: "pointer",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
            key={org.name}
          >
            <div
              style={{
                fontSize: "18.5px",
                textDecorationLine: "underline",
                fontFamily: "manrope",
                fontWeight: "bold",
                width: "25%",
                textAlign: "center",
              }}
            >
              {org.name}:
            </div>
            <div
              style={{
                fontSize: "17px",
                fontFamily: "manrope",
                marginLeft: "25px",
              }}
            >
              {org.total} Outlets
            </div>
            <div
              style={{
                fontSize: "17px",
                fontFamily: "manrope",
                marginLeft: "25px",
                color: green,
              }}
            >
              {org.connected} Connected
            </div>
            <div
              style={{
                fontSize: "17px",
                fontFamily: "manrope",
                marginLeft: "25px",
                color: greyDark,
              }}
            >
              {org.disconnected} Disconnected
            </div>
            <div
              style={{
                fontSize: "17px",
                fontFamily: "manrope",
                marginLeft: "25px",
                color: "orange",
              }}
            >
              {org.notActivated} Not Activated
            </div>
            <div
              style={{
                fontSize: "17px",
                fontFamily: "manrope",
                marginLeft: "25px",
                color: "orange",
              }}
            >
              {org.active} Scheduled
            </div>
          </div>
        ))}
      </Box>
      {/* <OrganizationModal
        open={modalOpen}
        handleClose={handleModalClose}
        org={selectedOrg}
        organizations={organizations}
        orgID={orgID}
      /> */}
    </View>
  );
}
