export const black = '#000000';
export const white = '#FFFFFF';
export const neutral800 = '#1F1F1F';
export const neutral700 = '#2D2D2D';
export const neutral600 = '#4B4B4B';
export const neutral500 = '#666666';
export const neutral400 = '#999999';
export const neutral300 = '#CCCCCC';
export const neutral350 = 'rgba(204, 204, 204, .5)';
export const neutral330 = 'rgba(204, 204, 204, .3)';
export const neutral200 = '#EEEEEE';
export const neutral100 = '#F5F5F5';
export const neutral50 = '#FAFAFA';
export const primary700 = '#520307';
export const primary600 = '#9B060E';
export const primary500 = '#E50914';
export const primary580 = 'rgba(229, 9, 20, 0.8)';
export const primary550 = 'rgba(229, 9, 20, 0.5)';
export const primary400 = '#F6A6AA';
export const primary300 = '#FCE1E3';
export const orange300 = '#E56208';
export const orange100 = '#FEF8F2';
export const green300 = '#00A033';
export const green100 = '#DCFFEC';
export const purple300 = '#8E08E5';
export const blue300 = '#004CD3';
export const blue200 = '#2789FF';
export const blue100 = '#D5EDFF';
export const yellow300 = '#D1AD07';
export const elevationLight1 = '0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D';
export const elevationLight2 = '0px 2px 6px 2px #00000026, 0px 1px 2px 0px #0000004D';
export const elevationLight3 = '0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026';
export const elevationLight4 = '0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026';
export const elevationLight5 = '0px 4px 4px 0px #0000004D, 0px 8px 12px 6px #00000026';
export const elevationDark1 = '0px 1px 2px 0px #0000004D, 0px 1px 3px 1px #00000026';
export const elevationDark1Color = '#1D1B20';
export const elevationDark2 = '0px 1px 2px 0px #0000004D, 0px 2px 6px 2px #00000026';
export const elevationDark2Color = '#211F26';
export const elevationDark3 = '0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026';
export const elevationDark3Color = '#2B2930';
export const elevationDark4 = '0px 2px 3px 0px #0000004D, 0px 6px 10px 4px #00000026';
export const elevationDark4Color = '#2B2930';
export const elevationDark5 = '0px 4px 4px 0px #0000004D, 0px 8px 12px 6px #00000026';
export const elevationDark5Color = '#36343B';
export const grey500 = '#8E8E8E';
export const grey600 = '#4B4B4B';

export default {
    black,
    white,
    neutral800,
    neutral700,
    neutral600,
    neutral500,
    neutral400,
    neutral300,
    neutral350,
    neutral330,
    neutral200,
    neutral100,
    neutral50,
    primary700,
    primary600,
    primary500,
    primary580,
    primary550,
    primary400,
    primary300,
    orange300,
    orange100,
    green300,
    green100,
    purple300,
    blue300,
    blue200,
    blue100,
    yellow300,
    elevationLight1,
    elevationLight2,
    elevationLight3,
    elevationLight4,
    elevationLight5,
    elevationDark1,
    elevationDark2,
    elevationDark3,
    elevationDark4,
    elevationDark5,
    elevationDark1Color,
    elevationDark2Color,
    elevationDark3Color,
    elevationDark4Color,
    elevationDark5Color,
    grey500,
    grey600,
};