import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

const renderCellContent = (params) => {
  const hasNoData = params.value === "No data";
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {params.value}
      {hasNoData && <ReportProblemIcon style={{ color: "grey" }} />}
    </div>
  );
};

const DataGridTable = ({ data, loading, handleDeleteOutlet }) => {
  const [open, setOpen] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const handleDeleteClick = (outlet) => {
    setSelectedOutlet(outlet);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedOutlet) {
      setOpen(false);
      handleDeleteOutlet(selectedOutlet);
    }
  };

  const columns = [
    {
      field: "organizationName",
      headerName: "Organization",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "outletName",
      headerName: "Name",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "outletID",
      headerName: "Device ID",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "outletStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        if (params.value === "No data") {
          return renderCellContent(params);
        }
        switch (params.value) {
          case "On":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <ToggleOnIcon style={{ color: "green" }} />
              </div>
            );
          case "Off":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <ToggleOffIcon style={{ color: "red" }} />
              </div>
            );
          default:
            return renderCellContent(params);
        }
      },
    },
    {
      field: "lastOffUpdate",
      headerName: "Last Off Update",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "lastOnUpdate",
      headerName: "Last On Update",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "lastDataUpdate",
      headerName: "Last Data Update",
      width: 200,
      renderCell: renderCellContent,
    },
    {
      field: "wifiStatus",
      headerName: "WiFi Status",
      width: 200,
      renderCell: (params) => {
        if (params.value === "No data") {
          return renderCellContent(params);
        }
        switch (params.value) {
          case "Connected":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiIcon style={{ color: "green" }} />
              </div>
            );
          case "Disconnected":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiOffIcon style={{ color: "red" }} />
              </div>
            );
          default:
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiLockIcon style={{ color: "grey" }} />
              </div>
            );
        }
      },
    },
    // Actions column
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          style={{
            cursor: "pointer",
            color: "red",
            marginRight: "15px",
          }}
          onClick={() =>handleDeleteClick(params.row)}
        />
      ),
    },
  ];

  const rows = data.map((outlet) => ({
    id: `${outlet.outletId}`,
    organizationName: outlet.orgName || "No data",
    organizationID: outlet.orgID || "No data",
    outletName: outlet.name || "No data",
    outletStatus: outlet.status || "No data",
    lastOffUpdate: outlet.lastOffUpdate || "No data",
    lastOnUpdate: outlet.lastOnUpdate || "No data",
    lastDataUpdate: outlet.lastDataUpdate || "No data",
    wifiStatus: outlet.wifiStatus || "No data",
    outletID: outlet.id || "No data",
    device: outlet.device || "No data",
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        sx={{ width: "90%", backgroundColor: "white" }}
        getRowHeight={() => "auto"}
      />

      {/* Confirmation Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete outlet "{selectedOutlet?.outletName}
            " (ID: {selectedOutlet?.outletID})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataGridTable;
