import React, { useEffect, useState } from "react";
import '../../App.css'; // Import your fonts

export default function Badge({
    label,
}) {

    return (
        <div
            style={{
                width:label ? "16px" : "6px",
                height: label ? "16px" : '6px',
                minWidth: label ? "16px" : "6px",
                maxWidth: '34px',
                borderRadius: '100px',
                // opacity: '0px',
                backgroundColor: '#B3261E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
            }}
        >
            {label ?
                <div className = "labelSmall"
                    style={{color:'#FFFFFF'}}
                >
                    {label}
                </div>
                : null
            }
        </div>
    );
};