import React, { useState, useEffect } from "react";
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { useOutletContext } from "react-router-dom";
import { fetchOutlets } from "../../functions/outletList";
import { fetchSavings } from "../../functions/savings";
import { fetchOrganizationInfoFromID } from "../../functions/organization";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";

export default function SavingsGraph() {
    const {
        orgID,
        orgName,
        isOverviewAccount,
        selectedOrgID,
        // child_orgs,
        timezone,
        allProdTypes,
        allOrgs,
        setTimezone,
        setRate,
        setSelectedOrgID,
        // setChild_Orgs
    } = useOutletContext();
    const [outlets, setOutlets] = useState([]);
    const [savings, setSavings] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [child_orgs, setChild_Orgs] = useState([]);
    const [savingsLoading, setSavingsLoading] = useState(true);
    const [wattHours, setWattHours] = useState([]);
    const [wattsLoading, setWattsLoading] = useState(true);
    const [averageUsage, setAverageUsage] = useState([]);
    const [averageLoading, setAverageLoading] = useState(true);

    useEffect(() => {
        const fetchDashboardInfo = async () => {
            //get the (selected) org document information from the firebase
            let orgData = [];
            let orgNameArray = [];
            if (selectedOrgID !== "") {
                orgData = await fetchOrganizationInfoFromID(selectedOrgID);
                setOrgData([orgData]);
            } else if (orgID !== "") {
                orgData = await fetchOrganizationInfoFromID(orgID);
                setOrgData([orgData]);
            };

            //if the (selected) org has child orgs make sure to update the child orgs in storage
            if (orgData.child_orgs) {
                setChild_Orgs(orgData.child_orgs);
                localStorage.setItem('child_orgs', orgData.child_orgs);
                if (orgData.child_orgs.length > 0) {
                    const orgDataPromises = orgData.child_orgs.map(org => fetchOrganizationInfoFromID(org));
                    const orgDataArray = await Promise.all(orgDataPromises);
                    setOrgData(orgDataArray)
                };
            } else {
                setChild_Orgs([]);
                localStorage.setItem('child_orgs', []);
            };
            //get schedules and wattHours
            if (orgData.child_orgs) {
                if (orgData.child_orgs.length > 0) {
                    // fetchOrgSchedules(orgData.child_orgs, setSchedules, setScheduleLoading);
                    fetchWattHours(orgData.child_orgs, setWattHours, setWattsLoading);
                    fetchSavings(orgData.child_orgs, setSavings, setSavingsLoading);
                    fetchAverageUsage(orgData.child_orgs, setAverageUsage, setAverageLoading);
                };
            } else if (selectedOrgID !== "") {
                // fetchOrgSchedules([selectedOrgID], setSchedules, setScheduleLoading);
                fetchWattHours([selectedOrgID], setWattHours, setWattsLoading);
                fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
                fetchAverageUsage([selectedOrgID], setAverageUsage, setAverageLoading);
            } else {
                // fetchOrgSchedules([orgID], setSchedules, setScheduleLoading);
                fetchWattHours([orgID], setWattHours, setWattsLoading);
                fetchSavings([orgID], setSavings, setSavingsLoading);
                fetchAverageUsage([orgID], setAverageUsage, setAverageLoading);
            };
        };
        fetchDashboardInfo();
    }, [selectedOrgID,]);

    if (savings.length !== 0 && wattHours.length !== 0 && averageUsage.length !== 0) {
        // console.log(averageUsage);
        const wattHoursObject = wattHours.reduce((acc, item) => {
            const { date, outletID, hourlyWattHour } = item;
            if (!acc[date]) {
                acc[date] = [];
            };
            acc[date].push({
                outletID,
                hourlyWattHour,
            });
            return acc;
        }, {});
        // remove outlets that don't have active usage data
        const filterAverageUsage = (averageUsage, wattHours) => {
            return averageUsage.filter((outlet) =>
                wattHours.some((outletUsage) => outletUsage.outletID === outlet.outletID)
            );
        };
        const filteredAverageUsage = filterAverageUsage(averageUsage, wattHours);
        // console.log(filteredAverageUsage);
        const calculateTotalAverage = (filteredAverageUsage) => {
            // Initialize the totalAverage object with days of the week as keys
            const totalAverage = {
                Mon: Array(24).fill(0),
                Tue: Array(24).fill(0),
                Wed: Array(24).fill(0),
                Thu: Array(24).fill(0),
                Fri: Array(24).fill(0),
                Sat: Array(24).fill(0),
                Sun: Array(24).fill(0),
            };

            filteredAverageUsage.forEach((outlet) => {
                const { average_usage_data } = outlet;

                // Accumulate the values for each day and hour
                Object.keys(average_usage_data).forEach((day) => {
                    average_usage_data[day].forEach((value, hour) => {
                        totalAverage[day][hour] += value / 1000;
                    });
                });
            });

            return totalAverage;
        };
        const dailyAverage = calculateTotalAverage(filteredAverageUsage);
        console.log('daily avg', dailyAverage);


        const savingsObject = savings.reduce((acc, item) => {
            const { date, outletID, co2eSavings, costSavings, energySavings } = item;
            if (!acc[date]) {
                acc[date] = [];
            };
            acc[date].push({
                outletID,
                savings: {
                    co2eSavings,
                    costSavings,
                    energySavings,
                },
            });
            return acc;
        }, {});
        const aggregateByMonthCost = (data) => {
            const monthlySavings = {};

            Object.keys(data).forEach((date) => {
                const [year, month] = date.split("-"); // Extract year and month from the date
                const key = `${year}-${month}`;
                const totalCostSavings = data[date].reduce(
                    (sum, outlet) => sum + outlet.savings.costSavings,
                    0
                );
                if (!monthlySavings[key]) {
                    monthlySavings[key] = 0;
                };
                monthlySavings[key] += totalCostSavings;
            });
            return Object.entries(monthlySavings)
                .map(([key, totalCostSavings]) => {
                    const [year, month] = key.split("-");
                    return {
                        year: parseInt(year, 10),
                        month: parseInt(month, 10),
                        totalCostSavings,
                    };
                }).sort((a, b) => {
                    // Sort by year first, then by month
                    if (a.year === b.year) {
                        return a.month - b.month;
                    };
                    return a.year - b.year;
                });
        };
        const aggregateByMonthCO2 = (data) => {
            const monthlySavings = {};

            Object.keys(data).forEach((date) => {
                const [year, month] = date.split("-"); // Extract year and month from the date
                const key = `${year}-${month}`;
                const totalCostSavings = data[date].reduce(
                    (sum, outlet) => sum + outlet.savings.co2eSavings,
                    0
                );
                if (!monthlySavings[key]) {
                    monthlySavings[key] = 0;
                };
                monthlySavings[key] += totalCostSavings;
            });
            return Object.entries(monthlySavings)
                .map(([key, totalCostSavings]) => {
                    const [year, month] = key.split("-");
                    return {
                        year: parseInt(year, 10),
                        month: parseInt(month, 10),
                        totalCostSavings,
                    };
                }).sort((a, b) => {
                    // Sort by year first, then by month
                    if (a.year === b.year) {
                        return a.month - b.month;
                    };
                    return a.year - b.year;
                });
        };
        const aggregateByMonthEnergy = (data) => {
            const monthlySavings = {};

            Object.keys(data).forEach((date) => {
                const [year, month] = date.split("-"); // Extract year and month from the date
                const key = `${year}-${month}`;
                const totalCostSavings = data[date].reduce(
                    (sum, outlet) => sum + outlet.savings.energySavings,
                    0
                );
                if (!monthlySavings[key]) {
                    monthlySavings[key] = 0;
                };
                monthlySavings[key] += totalCostSavings;
            });
            return Object.entries(monthlySavings)
                .map(([key, totalCostSavings]) => {
                    const [year, month] = key.split("-");
                    return {
                        year: parseInt(year, 10),
                        month: parseInt(month, 10),
                        totalCostSavings,
                    };
                }).sort((a, b) => {
                    // Sort by year first, then by month
                    if (a.year === b.year) {
                        return a.month - b.month;
                    };
                    return a.year - b.year;
                });
        };
        const aggregateByMonthWattHour = (data) => {
            const monthlyWattHour = {};
            Object.keys(data).forEach((date) => {
                const [year, month] = date.split("-");
                const key = `${year}-${month}`;
                const totalWattHour = data[date].reduce((sum, outlet) => {
                    // Sum the values in hourlyWattHour array, ignoring -1s
                    const outletTotal = outlet.hourlyWattHour
                        .filter((value) => value !== -1) // Exclude -1s
                        .reduce((acc, value) => acc + value, 0); // Sum the valid values
                    return sum + outletTotal; // Add to the running total
                }, 0);

                if (!monthlyWattHour[key]) {
                    monthlyWattHour[key] = 0;
                };
                monthlyWattHour[key] += totalWattHour;
            });
            return Object.entries(monthlyWattHour)
                .map(([key, totalWattHour]) => {
                    const [year, month] = key.split("-");
                    return {
                        year: parseInt(year, 10),
                        month: parseInt(month, 10),
                        totalWattHour,
                    };
                }).sort((a, b) => {
                    // Sort by year first, then by month
                    if (a.year === b.year) {
                        return a.month - b.month;
                    };
                    return a.year - b.year;
                });
        };
        const aggregateByHourWattHour = (data, targetDate) => {
            const hourlyTotals = Array(24).fill(0);

            // Ensure the targetDate exists in the data
            if (!data[targetDate]) {
                return hourlyTotals.map((total, hour) => ({
                    hour,
                    totalWattHour: total, // Default to 0 for missing date
                }));
            };

            // Iterate over outlets for the target date
            data[targetDate].forEach((outlet) => {
                outlet.hourlyWattHour.forEach((value, hour) => {
                    if (value !== -1) { // Ignore -1 values
                        hourlyTotals[hour] += value;
                    };
                });
            });

            // Map the totals into the desired format for the chart
            return hourlyTotals.map((total, hour) => ({
                hour,
                totalWattHour: total,
            }));
        };

        const wattHourChartData = aggregateByMonthWattHour(wattHoursObject);


        const modifiedWattHourChartData = wattHourChartData.map(item => ({
            ...item,
            totalWattHour: item.totalWattHour / 1000, // Divide by 1000
        }));
        // this uses a tuesday for testing
        const singleDayWattHoursChartData = aggregateByHourWattHour(wattHoursObject, '2024-11-19');

        const modifiedSingleDayWattHoursChartData = singleDayWattHoursChartData.map(item => ({
            ...item,
            totalWattHour: item.totalWattHour / 1000,
        }));

        // using Tue as an example as it aligns with day from singleDayWattHours, this would need to be programatic depending on the day
        const singleDayWattHoursWithAverage = modifiedSingleDayWattHoursChartData.map((entry, index) => ({
            ...entry,
            averageWattHour: dailyAverage.Tue[index]
        }));

        console.log('watt hour with avg', singleDayWattHoursWithAverage);

        const savingsChartData = aggregateByMonthCost(savingsObject);
        const co2ChartData = aggregateByMonthCO2(savingsObject);
        const energyChartData = aggregateByMonthEnergy(savingsObject);
        const modifiedEnergyChartData = energyChartData.map(item => ({
            ...item,
            totalCostSavings: item.totalCostSavings / 1000, // Divide by 1000
        }));

        const keyToLabel = {
            totalCostSavings: "Total Cost Savings",
        };
        const colors = {
            totalCostSavings: "#8884d8",
        };
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // console.log(savingsChartData, co2ChartData, modifiedEnergyChartData);
        const totalCostSavingsSum = savingsChartData.reduce((acc, item) => acc + item.totalCostSavings, 0);
        const totalCostSavingsSumCO2 = co2ChartData.reduce((acc, item) => acc + item.totalCostSavings, 0);
        const totalCostSavingsSumEnergy = modifiedEnergyChartData.reduce((acc, item) => acc + item.totalCostSavings, 0);


        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', }}>
                    {/* cost */}
                    <div style={{
                        width: '500px',
                        height: '500px'
                    }}>
                        {/* placeholder */}
                        <LineChart
                            dataset={savingsChartData}
                            series={[
                                {
                                    dataKey: "totalCostSavings",
                                    label: 'Dollar Savings',
                                    // color: colors.totalCostSavings,
                                    showMark: true,
                                    area: true,
                                    curve: 'catmullRom'
                                },
                            ]}
                            xAxis={[
                                {
                                    dataKey: 'month',
                                    valueFormatter: (value) => monthNames[value - 1],
                                    min: 1,
                                    max: 12,
                                },
                            ]}
                        />
                    </div>
                    {/* co2 */}
                    <div style={{
                        width: '500px',
                        height: '500px'
                    }}>
                        {/* placeholder */}
                        <LineChart
                            dataset={co2ChartData}
                            series={[
                                {
                                    dataKey: "totalCostSavings",
                                    label: 'CO2e Savings',
                                    // color: colors.totalCostSavings,
                                    showMark: true,
                                    area: true,
                                    curve: 'catmullRom'
                                },
                            ]}
                            xAxis={[
                                {
                                    dataKey: 'month',
                                    valueFormatter: (value) => monthNames[value - 1],
                                    min: 1,
                                    max: 12,
                                },
                            ]}
                        />
                    </div>
                    {/* energy */}
                    <div style={{
                        width: '500px',
                        height: '500px'
                    }}>
                        {/* placeholder */}
                        <LineChart
                            dataset={modifiedEnergyChartData}
                            series={[
                                {
                                    dataKey: "totalCostSavings",
                                    label: 'Energy Savings',
                                    // color: colors.totalCostSavings,
                                    showMark: true,
                                    area: true,
                                    curve: 'catmullRom'
                                },
                            ]}
                            xAxis={[
                                {
                                    dataKey: 'month',
                                    valueFormatter: (value) => monthNames[value - 1],
                                    min: 1,
                                    max: 12,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <div>
                        Lifetime Dollar Savings: ${totalCostSavingsSum.toFixed(2)}
                    </div>
                    <div>
                        Lifetime C02e Savings: {totalCostSavingsSumCO2.toFixed(2)} lbs
                    </div>
                    <div>
                        Lifetime Energy Savings: {totalCostSavingsSumEnergy.toFixed(2)} kWhs
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{
                        width: '500px',
                        height: '500px'
                    }}>
                        {/* placeholder */}
                        <LineChart
                            dataset={modifiedWattHourChartData}
                            series={[
                                {
                                    dataKey: "totalWattHour",
                                    label: 'kWh',
                                    // color: colors.totalCostSavings,
                                    showMark: true,
                                    area: true,
                                    curve: 'catmullRom'
                                },
                            ]}
                            xAxis={[
                                {
                                    dataKey: 'month',
                                    valueFormatter: (value) => monthNames[value - 1],
                                    // min: 1,
                                    // max: 12,
                                },
                            ]}
                        />
                    </div>
                    <div style={{
                        width: '750px',
                        height: '500px'
                    }}>
                        <LineChart
                            dataset={singleDayWattHoursWithAverage}
                            sx={{
                                '& .MuiLineElement-series-averageWattHour': {
                                    stroke: '#E56208',              // Line color
                                    strokeDasharray: '5 5',       // Dashed line pattern
                                    strokeWidth: 3,                // Line width
                                },
                                '& .MuiAreaElement-series-averageWattHour': {
                                    fill: "url('#averageGradient')",
                                },
                                '& .MuiAreaElement-series-totalWattHour': {
                                    fill: "url('#usageGradient')",
                                },
                            }}
                            series={[
                                {
                                    dataKey: "averageWattHour",
                                    id: 'averageWattHour',
                                    label: 'Without Revert',
                                    color: "#E56208",
                                    area: true,
                                    curve: 'catmullRom',
                                    showMark: true
                                },
                                {
                                    dataKey: "totalWattHour",
                                    id: "totalWattHour",
                                    label: 'With Revert',
                                    color: '#2789FF',
                                    showMark: true,
                                    area: true,
                                    curve: 'catmullRom',
                                },
                            ]}
                            xAxis={[
                                {
                                    dataKey: "hour",
                                    valueFormatter: (value) => {
                                        const hour = value % 12 || 12;
                                        const ampm = value < 12 ? 'AM' : 'PM';
                                        return `${hour}:00 ${ampm}`;
                                    },
                                    min: 0,
                                    max: 23,
                                },
                            ]}
                            yAxis={[
                                {
                                    label: "kWhs",
                                    position: "top",
                                    offset: 15,
                                    fontSize: 14,
                                    color: 'black',
                                },
                            ]}
                        >
                            <defs>
                                <linearGradient id="averageGradient" gradientTransform="rotate(90)">
                                    <stop offset="0%" stopColor="#E56208" stopOpacity={.55} />
                                    <stop offset="50%" stopColor="#E56208" stopOpacity={.25} />
                                    <stop offset="100%" stopColor="#E56208" stopOpacity={.1} />
                                </linearGradient>
                                <linearGradient id="usageGradient" gradientTransform="rotate(90)">
                                    <stop offset="0%" stopColor="#2789FF" stopOpacity={.75} />
                                    <stop offset="50%" stopColor="#2789FF" stopOpacity={.45} />
                                    <stop offset="100%" stopColor="#2789FF" stopOpacity={.2} />
                                </linearGradient>
                            </defs>
                        </LineChart>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                No savings
            </div>
        );
    };
};