import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logout } from "../../firebase";
import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { fetchUserRole } from "../../functions/users";
import { blue, green } from "../../lib/colors";

export default function PendingRoleScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (waiting) {
      return;
    }
    const getRole = async () => {
      setLoading(true);
      const userRole = await fetchUserRole(user.uid);
      setLoading(false);
      if (userRole !== "pending") {
        navigate("/");
      }
    };

    if (user) {
      getRole();
    } else {
      navigate("/login");
    }
  }, [user, waiting]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "white",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography>Please contact your admin to assign you a role</Typography>
      <Button
        onClick={() => {
          if (user) {
            logout();
            localStorage.clear();
            window.localStorage.clear()
            window.location.reload();
          }
        }}
        type="submit"
        variant="contained"
        color="primary"
        //   onClick={handleSubmit}
        //   disabled={!orgFound || !emailValid || !isValidPassword(password)}
        style={{ backgroundColor: blue }}
      >
        Sign out
      </Button>
    </Box>
  );
}
