import React, { useEffect, useState } from "react";
import IconButton from "../iconButton";
import { primary500 } from "../../lib/rebrandColors/colors";

export default function TimePicker({ timeSpan, setTime }) {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        updateSelectedTimeFrame(currentDate); // Update based on current date
    }, [timeSpan, currentDate]);

    // used for weeks
    const formatDateRange = (startDate, endDate) => {
        const startMonth = startDate.toLocaleDateString('en-US', { month: 'long' });
        const startDay = startDate.toLocaleDateString('en-US', { day: 'numeric' });
        const endMonth = endDate.toLocaleDateString('en-US', { month: 'long' });
        const endDay = endDate.toLocaleDateString('en-US', { day: 'numeric' });
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        if (startMonth === endMonth) {
            return `${startMonth} ${startDay} - ${endDay}`;
        } else if (startYear !== endYear) {
            // If the years are different
            return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
        } else {
            // If the months are different but the year is the same
            return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
        };
    };
    const getWeekRange = () => {
        const startOfWeek = new Date(currentDate);
        // Calculate the start of the week (Monday)
        const dayOfWeek = startOfWeek.getDay(); // 0 for Sunday, 1 for Monday, etc.
        const adjustment = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // If Sunday, adjust to previous Monday
        startOfWeek.setDate(currentDate.getDate() + adjustment); // Set to the previous Monday

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Get the Sunday of the same week

        return formatDateRange(startOfWeek, endOfWeek);
    };

    const updateSelectedTimeFrame = (newDate) => {
        const currentYear = new Date().getFullYear();
        if (timeSpan === 'Day') {
            setSelectedTimeFrame(newDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }));
            setTime(newDate);
        } else if (timeSpan === 'Week') {
            setSelectedTimeFrame(getWeekRange());
            // NOTE: should maybe just be newDate?
            setTime(newDate);
        } else if (timeSpan === 'Month') {
            const monthName = newDate.toLocaleDateString('en-US', { month: 'long' });
            const year = newDate.getFullYear();
            if (year === currentYear) {
                setSelectedTimeFrame(monthName);
            } else {
                setSelectedTimeFrame(`${monthName} ${year}`);
            };
            setTime(newDate);
        } else if (timeSpan === 'Season') {

        };
    };

    const leftPress = () => {
        const newDate = new Date(currentDate);
        const adjustmentMargin = timeSpan === 'Week' ? 7 : (timeSpan === 'Month' ? 1 : 1);
        newDate.setDate(newDate.getDate() - adjustmentMargin);
        setCurrentDate(newDate);
    };
    const rightPress = () => {
        const newDate = new Date(currentDate);
        const adjustmentMargin = timeSpan === 'Week' ? 7 : (timeSpan === 'Month' ? 1 : 1);
        newDate.setDate(newDate.getDate() + adjustmentMargin);
        setCurrentDate(newDate);
    };
    const nextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1); // Move to the next month
        setCurrentDate(newDate);
        updateSelectedTimeFrame(newDate);
    };
    const previousMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1); // Move to the previous month
        setCurrentDate(newDate);
        updateSelectedTimeFrame(newDate);
    };

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: timeSpan === 'Month' ? '250px' : timeSpan === 'Week' ? '450px' : '200px'
            }}>
                {/* likely need to set some custom styling for icon buttons */}
                <IconButton
                    iconName="arrow-left"
                    buttonType="none"
                    tone="light"
                    onClick={timeSpan === 'Month' ? previousMonth : leftPress}
                />
                <div
                    className="buttonMedium"
                    style={{
                        color: primary500,
                        textAlign: 'center',
                        width: timeSpan === 'Month' ? '150px' : timeSpan === 'Week' ? '300px' : '100px'
                    }}
                >
                    {selectedTimeFrame}
                </div>
                <IconButton
                    iconName="arrow-right-1"
                    buttonType="none"
                    tone="light"
                    onClick={timeSpan === 'Month' ? nextMonth : rightPress}
                />
            </div>
        </>
    );
};