import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Alert, // Import Alert from Material UI
} from "@mui/material";
import {
  black,
  elevationLight1,
  neutral300,
  neutral400,
  primary300,
  primary500,
  grey600,
  white,
} from "../../lib/rebrandColors/colors";
import UsageDashboardContainer from "../../pages/home/usageDashboardContainer";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import { green, greyDark, orange } from "../../lib/colors";
import { storage } from "../../firebase";
import '../../App.css'; // Import your fonts
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import imageCompression from "browser-image-compression";
import { submitNameEdit, submitPhotoEdit, deletePhotoEdit, toggleOutletStatus, productSelectPress } from "../../functions/outletList";
import CloseIcon from '@mui/icons-material/Close';
import TextField from '../textField';
import Dropdown from "../dropDown";
import CustomButton from "../customButton";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import IconButton from "../iconButton";
import DailyUsageChart from "../dailyUsageChart";
import SavingsDisplay from "../savingsDisplay";

function statusToBool(status) {
  return status === "On";
};

export default function OutletModal({
  isOpen,
  setIsModalOpen,
  outlet,
  setOutlet,
  oldName,
  rate,
  selectOnPress,
  width,
  height,
  outlets,
  setOutlets,
  outletData,
  prodTypes,
  modalEdit,
  setModalEdit,
  wattHours,
  savings,
  averageUsage,
  role,
}) {
  // const [wattsLoading, setWattsLoading] = useState(true);
  // const [wattHours, setWattHours] = useState([]);
  const [outletAverageUsage, setOutletAverageUsage] = useState([]);
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID"));
  const [dataFetched, setDataFetched] = useState(false);
  const [status, setStatus] = useState(outlet.status);
  const [name, setName] = useState(outlet.name);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID")
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [outletUrl, setOutletUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility
  const [deleteClick, setDeleteClick] = useState(false);
  //const index = outlets.findIndex((obj) => obj.id === outlet.id);

  // placeholder for graph
  const wattsLoading = false;
  const savingsLoading = false;
  const averageLoading = false;

  const handlePower = () => {
    let newStatus;
    if (status == 'On') {
      newStatus = 'Off'
    } else {
      newStatus = 'On'
    }
    setOutlet({ ...outlet, status: newStatus });
    setStatus(newStatus);
    toggleOutletStatus(newStatus, outlet.orgID, outlet.id, outlet.docID);
    setOutlets((prevOutlets) =>
      prevOutlets.map((o) => (o.id === outlet.id ? { ...o, status: newStatus } : o))
    );
    //setModalEdit(!modalEdit);
  };

  // const toggleOn = async () => {
  //   toggleOutletStatus("On", outlet.orgID, outlet.id, outlet.docID);
  //   console.log("On");
  //   //outlets[index].status = "On";
  //   setStatus("On");
  //   //setOutlets(outlets);
  // };
  // const toggleOff = async () => {
  //   toggleOutletStatus("Off", outlet.orgID, outlet.id, outlet.docID);
  //   console.log("Off")
  //   setStatus("Off");
  // };

  // useEffect(() => {
  //   if (isOpen && !dataFetched) {
  //     if (selectedOrgID) {
  //       fetchWattHours(selectedOrgID, setWattHours, setWattsLoading, outlet.id);
  //       fetchAverageUsage(selectedOrgID, setOutletAverageUsage, outlet.id);
  //     } else {
  //       fetchWattHours(orgID, setWattHours, setWattsLoading, outlet.id);
  //       fetchAverageUsage(orgID, setOutletAverageUsage, outlet.id);
  //     }
  //     setDataFetched(true);
  //   }
  // }, [isOpen, dataFetched, orgID, selectedOrgID, outlet.id]);

  const handleNameChange = (value) => {
    setOutlet({ ...outlet, name: value })
  };

  const handleSaveClick = async (event) => {
    if (oldName == outlet.name) {
      setModalEdit(!modalEdit);
      setIsModalOpen(false);
    }
    else {
      let success = await submitNameEdit(
        outlet.orgID,
        outlet.id,
        outlet.docID,
        outlet.name,
      );
      if (!success) {
        setShowAlert(true);
        setName(outlet.name,);
      } else {
        setShowAlert(false);
        setOutlets((prevOutlets) =>
          prevOutlets.map((o) => (o.id === outlet.id ? { ...o, name: name } : o))
        );
        setIsModalOpen(false);
        setModalEdit(!modalEdit);
        setIsModalOpen(false);
      }
    }
  };

  const handleApplianceChange = async (value) => {
    if (value !== outlet.productType) {
      if (value === "N/A") {
        value = ""
      }
      setOutlet({ ...outlet, productType: value })
      productSelectPress({ ...outlet, productType: value });
      setModalEdit(!modalEdit);
    }
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    if (file) {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });
      const storageRef = ref(storage, fileName);
      try {
        const snapshot = await uploadBytes(storageRef, compressedFile);
        console.log('Upload file', snapshot);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        setOutletUrl(downloadUrl);
        console.log('file at', downloadUrl);
        await submitPhotoEdit(outlet.docID);
      } catch (error) {
        console.error('error uploading', error);
      };
    };
  };

  const handleDeleteClick = async () => {
    setDeleteClick(!deleteClick)
  };
  const handleDelete = async (event) => {
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    const storageRef = ref(storage, fileName);
    // Delete the file
    await deletePhotoEdit(outlet.docID);
    deleteObject(storageRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    setOutletUrl();
    setDeleteClick(false)
  };

  useEffect(() => {
    if (isOpen) {
      // console.log('outlet', outlet);
      const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
      const fetchImageUrl = async () => {
        try {
          const fileRef = ref(storage, fileName);
          const url = await getDownloadURL(fileRef);
          setOutletUrl(url);
          // console.log('url', url);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        };
      };
      if (outlet.imageURl == true) {
        fetchImageUrl();
      }
    };
  }, [isOpen]);
  const singleOutletWattHours = wattHours.filter(item => item.outletID === outlet.id);
  const singleOutletAverageUsage = averageUsage.filter(item => item.outletID === outlet.id);
  const singleOutletSavings = savings.filter(item => item.outletID === outlet.id);
  // console.log('usage', outlet, singleOutletWattHours, singleOutletAverageUsage, singleOutletSavings);



  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason != 'backdropClick' && reason != 'escapeKeyDown') {
          setIsModalOpen(false);
          setDataFetched(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "58%",
          height: "75%",
          backgroundColor: '#FAFAFA',
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box sx={{
            width: '250px',
          }}>
            <div className="headlineLarge">
              {outlet.name}
            </div>
            {orgID === 'LZqI3R6MInUuwtgtROPK' &&
              <div className="headlineSmall" >
                Outlet ID: {outlet.device}
              </div>
            }
          </Box>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px', // Space between buttons
            // marginTop: outletUrl ? '50px' : '0px',
          }}>
            {status === 'Off' ?
              <div className="buttonMedium">Click to turn outlet on:</div>
              :
              <div className="buttonMedium">Click to turn outlet off:</div>
            }
            <IconButton
              size={75}
              tone={status == 'Off' ? 'light' : 'dark'}
              icon={
                <PowerSettingsNewIcon
                  style={{
                    color: status == 'Off' ? neutral400 : neutral300,
                    fontSize: '40px'
                  }}
                />
              }
              onClick={handlePower}
              disabled={role === 'view' ? true : false}
            />
          </div>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, width: '225px', }}
          >
            <IconButton tone='light' onClick={handleSaveClick} icon={<CloseIcon />} />
          </Box>
        </Box>

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          marginTop: '30px'
        }}>
          <TextField
            label="Edit Name"
            //defaultValue={outlet.name}
            incomingValue={outlet.name}
            onChange={handleNameChange}
            size='medium'
            background='light'
            placeholder="Add a name for this appliance"
            disabled={role === 'view' ? true : false}
          />
          <div style={{ marginTop: '-10px', marginLeft: '15px' }}>
            <Dropdown
              label='Edit Appliance Type'
              list={prodTypes}
              value={outlet.productType ? outlet.productType : "N/A"}
              setIsDropdownOpen={setIsDropdownOpen}
              isDropdownOpen={isDropdownOpen}
              onSelect={handleApplianceChange}
              disabled={role === 'view' ? true : false}
            />
          </div>
        </div>

        <Box sx={{
          alignSelf: 'center',
          alignItems: 'center',
          marginTop: '30px',
          display: 'flex',
          flexDirection: 'row'
        }}>
          {outletUrl ?
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '400px',
              height: '350px',
            }}>
              <img
                src={outletUrl}
                alt="Photo of Outlet"
                style={{
                  maxWidth: '300px',
                  maxHeight: '300px',
                }}
              />
              <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginTop: '20px',
              }}>
                <CustomButton
                  label="Update"
                  iconName='cloud-upload'
                  onClick={handleUploadClick}
                  filled={true}
                  size='small'
                  disabled={role === 'view' ? true : false}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <CustomButton
                  label='Delete'
                  iconName='delete-2'
                  onClick={handleDeleteClick}
                  variant="contained"
                  disabled={role === 'view' ? true : false}
                />
              </Box>
              <Modal
                open={deleteClick}
                onClose={(event) => {
                  handleDeleteClick(event);
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    height: "8%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflow: "auto",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center'
                  }}
                >
                  <div>Confirm you would like to delete the image</div>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: '20px' }}
                  >
                    <Button onClick={handleDeleteClick} variant="outlined">
                      Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="contained" style={{ backgroundColor: 'red' }}>
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
            :
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '400px',
              height: '350px',
            }}>
              <button
                style={{
                  width: '300px',
                  height: '300px',
                  borderWidth: '3px',
                  borderStyle: 'dashed',
                  borderColor: '#999999',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  fontFamily: 'Manrope',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={handleUploadClick}
                className="bodyLarge"
                disabled={role === 'view' ? true : false}
              >
                Upload Image
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          }
          <div style={{
            width: "505px",
            borderRadius: '24px',
            minHeight: '500px',
            maxHeight: '620px',
            overflowY: 'hidden',
            marginTop: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <DailyUsageChart
              wattHours={singleOutletWattHours}
              wattsLoading={wattsLoading}
              averageUsage={singleOutletAverageUsage}
              averageLoading={averageLoading}
              savings={singleOutletSavings}
              savingsLoading={savingsLoading}
            />
            <SavingsDisplay
              savings={singleOutletSavings}
              savingsLoading={savingsLoading}
            />
          </div>
        </Box>

        {showAlert && (
          <Alert
            severity="warning"
            onClose={() => setShowAlert(false)}
            style={{ marginTop: 16 }}
          >
            The outlet name already exists. Please choose a different name.
          </Alert>
        )}

        {/* <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px', // Space between buttons
          marginTop: outletUrl ? '50px' : '0px',
        }}>
          {status === 'Off' ?
            <div className="buttonLarge">Click to turn outlet on:</div>
            :
            <div className="buttonLarge">Click to turn outlet off:</div>
          }
          <IconButton
            size={100}
            tone={status == 'Off' ? 'light' : 'dark'}
            icon={
              <PowerSettingsNewIcon
                style={{
                  color: status == 'Off' ? neutral400 : neutral300,
                  fontSize: '40px'
                }}
              />
            }
            onClick={handlePower}
          />
        </div> */}
      </Box>
    </Modal >
  );
};
