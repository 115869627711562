import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomIcon from "../../components/icon";
import {
  black,
  neutral400,
  neutral500,
  neutral600,
  neutral700,
  primary500,
  primary600,
  white,
} from "../../lib/rebrandColors/colors";

export default function IconButton({
  size = 50, // Size of the circle
  iconName, //= 'dashboard-square', // Icon component passed in the middle -> We can use our CustomIcon Component here directly
  icon,
  color,
  buttonType = 'tonal',
  tone = 'dark', // 'light' or 'dark' background -> null in case is not a tonal button
  disabled = false, // Whether the button is disabled
  onClick,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const getIconColor = () => {
    if (color) {
      return color
    }else if (tone === "light") {
      if (isPressed) {
        if (buttonType === 'none') {
          return primary600;
        };
        return white;
      } else if (isHovered && !isFocused) {
        if (buttonType === 'none') {
          return primary600;
        };
        return white;
      } else if (isFocused) {
        return primary500;
      };
      return primary500; // Default for light tone
    };
    return white; // Default for dark tone
  };

  // Here are the conditional background colors based on tone and state
  const getBackgroundColor = () => {
    if (buttonType == 'tonal') {
      if (disabled) {
        return tone === "light"
          ? white
          : neutral600
      } else if (isPressed) {
        return primary600;
      } else if (isFocused) {
        return tone === "light"
          ? white
          : neutral600;
      } else if (isHovered) {
        return primary500;
      } else {
        return tone === "light"
          ? white
          : neutral600;
      };
    } else if (buttonType == 'filled') {
      if (isPressed) {
        return neutral600;
      } else if (isFocused) {
        return neutral600;
      } else if (isHovered) {
        return neutral400;
      } else {
        return neutral600;
      };
    } else if (buttonType == 'standard') {
      // I will add the colors themselves here so we decide which version we like the most
      if (disabled) {
        return "rgba(0, 0, 0, 0)"; // Fully transparent
      } else if (isPressed) {
        return neutral400;
      } else if (isFocused) {
        return black;
      } else if (isHovered) {
        return neutral600;
      } else {
        return "rgba(0, 0, 0, 0)";
      };
    } else if (buttonType == 'outlined') {
      if (disabled) {
        return "rgba(0, 0, 0, 0)";
      } else if (isPressed) {
        return neutral400;
      } else if (isFocused) {
        return black;
      } else if (isHovered) {
        return neutral600;
      } else {
        return black;
      };
    };
  };

  const getBorder = () => {
    // Check for tone-based borders
    if (buttonType == 'tonal') {
      if (isFocused && !isPressed) {
        return tone === "light"
          ? `1px solid ${primary500}`
          : `1px solid ${white}`;
      };
    } else if (buttonType == 'filled') {
      // Check for filled button borders
      if (isFocused && !isPressed) {
        return `1px solid ${neutral500}`;
      };
    } else if (buttonType == 'standard') {
      // Check for standard button borders
      if (isFocused && !isPressed) {
        return `1px solid ${neutral400}`;
      };
    } else if (buttonType == 'outlined') {
      // Default for outlined buttons
      return `1px solid ${white}`;
    };
    // If no conditions match, return a default transparent border (optional)
    return `1px solid transparent`;
  };

  // Clone the icon with the overridden color -> So we can change the color dynamically when changing the button state
  // const clonedIcon = React.cloneElement(icon, {
  //   color: getIconColor(),
  //   style: {stroke: getIconColor() }
  // });

  const handleClick = () => {
    if (!disabled) {
        onClick();
    }
};

  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getBackgroundColor(),
        cursor: disabled ? "not-allowed" : "pointer",
        border: getBorder(),
        transition: "background-color 0.2s ease",
        opacity: disabled ? 0.3 : 1,
        "&:hover": !disabled && {
          backgroundColor: getBackgroundColor(),
        },
        "&:focus": !disabled && {
          backgroundColor: getBackgroundColor(),
        },
        "&:active": !disabled && {
          backgroundColor: getBackgroundColor(),
        },
      }}
      tabIndex={0}
      onMouseEnter={() => !disabled && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() =>  !disabled && setIsPressed(true) && setIsFocused(false)}
      onMouseUp={() => setIsPressed(false) && setIsHovered(false)}
      onFocus={() => !disabled && setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onClick={handleClick}
    >
      {iconName ? 
      <CustomIcon name={iconName} size={24} color={getIconColor()} /> :
      icon
      }
    </Box>
  );
};
