import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  //IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchOrganizationInfoFromID } from "../../functions/organization";
import '../../App.css'; // Import your fonts
import {
  neutral800,
  primary500,
  primary600,
  primary700,
  white,
  neutral700,
  neutral500,
  primary580,
} from "../../lib/rebrandColors/colors";
import Dropdown from "../dropDown";
import TextField from "../textField";
import CustomButton from "../customButton";
import IconButton from "../iconButton";

export default function SpecialEventForm({
  open,
  handleClose,
  handleSubmit,
  isOverviewAccount,
  child_org_data,
  organizationID,
  organizationName,
  outlets,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [subOrganization, setSubOrganization] = useState("");
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [action, setAction] = useState(false);
  const [childOrgs, setChildOrgs] = useState([]);
  const [iOrganizationDropdownOpen, setIOrganizationDropdownOpen] =
    useState(false);
  const [orgArray, setOrgArray] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);

  const [statusDropdownValue, setStatusDropdownValue] = useState('On');
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const statusDropdownClick = (item) => {
    // console.log('item', item);
    setStatusDropdownValue(item);
  };
  const [statusDropdownArr, setStatusDropdownArr] = useState(['On', 'Off']);

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  const onSubmit = () => {
    const eventData = {
      startDate: startDate,
      endDate: endDate,
      subOrganization: subOrganization,
      outlets: selectedOutlets,
      name: eventName,
      action: statusDropdownValue,
    };
    handleSubmit(eventData);
    setStartDate();
    setEndDate();
    setEventName('');
    setSelectedOutlets([]);
    setSelectedOrgs([]);
    handleClose();
  };

  const groupedOutlets = outlets.reduce((acc, outlet) => {
    (acc[outlet.orgID] = acc[outlet.orgID] || []).push(outlet);
    return acc;
  }, {});

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: '#FAFAFA',
          borderRadius: "24px",
          alignContent: "center",
          width: 564,
          height: 673,
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '40px',
          // marginLeft: '40px',
          gap: "20px",
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <div
              className="titleLarge"
              style={{ color: '#1F1F1F', textAlign: "Left" }}
            >
              Add Special Event
            </div>
            <IconButton iconName='cancel-1' tone='dark' size={24} onClick={handleClose} />
          </div>
          <TextField
            label="Special Event Name *"
            placeholder="Enter event name"
            required
            value={eventName}
            onChange={setEventName}
            size="medium"
            background='light'
          />
          {/* {child_org_data.length > 1 && (
            <FormControl sx={{ width: "485px", marginTop: '10px' }}>
              <InputLabel
                id="org-label"
                sx={{
                  color: neutral800,
                  "&.Mui-focused": {
                    color: neutral800,
                  },
                  fontFamily: "IBM Plex Sans",
                }}
              >
                Sub Organizations
              </InputLabel>
              <Select
                labelId="org-label"
                multiple
                value={selectedOrgs}
                onChange={(e) => {
                  setSelectedOrgs(e.target.value);
                }}
                label="Orgs"
                required
                renderValue={(selectedOrg) =>
                  selectedOrg.length ? selectedOrg.join(", ") : "Select Sub Organizations"
                }
                sx={{
                  backgroundColor: '#FAFAFA', //neutral700,
                  borderRadius: "34px",
                  marginTop: child_org_data && selectedOrgs.length > 0 ? "12px" : "0px",
                  border: '1px solid #999999',
                  color: '#999999',
                  "& .MuiSelect-icon": {
                    color: '#999999',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: '#999999',
                  },
                  "&.Mui-focused": {
                    marginTop: child_org_data ? "12px" : "0px"
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: neutral800,
                      "& .MuiMenuItem-root": {
                        color: '#999999',
                        "&:hover": {
                          backgroundColor: primary500,
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: primary500,
                        "&:hover": {
                          backgroundColor: primary500,
                        },
                      },
                    },
                  },
                }}
              >
                {child_org_data.map((org) => {
                  return (
                    <MenuItem key={org.uid} value={org.uid}>
                      {org.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )} */}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '485px',
            justifyContent: 'space-evenly'
          }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="End date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </LocalizationProvider>
          </div>
          <FormControl sx={{ width: "485px", marginTop: '10px' }}>
            <InputLabel
              id="outlet-label"
              sx={{
                color: neutral800,
                "&.Mui-focused": {
                  color: neutral800,
                },
                fontFamily: "IBM Plex Sans",
              }}
            >
              Outlets
            </InputLabel>
            <Select
              labelId="outlet-label"
              multiple
              value={selectedOutlets}
              onChange={(e) => setSelectedOutlets(e.target.value)}
              label="Outlets"
              required
              renderValue={(selected) =>
                selected.length ? selected.join(", ") : "Select Outlets"
              }
              sx={{
                backgroundColor: '#FAFAFA', //neutral700,
                borderRadius: "34px",
                marginTop: outlets && selectedOutlets.length > 0 ? "12px" : "0px",
                border: '1px solid #999999',
                color: '#999999',
                "& .MuiSelect-icon": {
                  color: '#999999',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: '#999999',
                },
                "&.Mui-focused": {
                  marginTop: outlets ? "12px" : "0px"
                }
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: neutral800,
                    "& .MuiMenuItem-root": {
                      color: '#999999',
                      "&:hover": {
                        backgroundColor: primary500,
                      },
                    },
                    "& .Mui-selected": {
                      backgroundColor: primary700,
                      "&:hover": {
                        backgroundColor: primary700,
                      },
                    },
                  },
                },
              }}
            >
              {outlets.map((outlet) => (
                <MenuItem key={outlet.id} value={outlet.id}>
                  {outlet.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <div
              className="bodyMedium"
              style={{ marginBottom: '10px' }}
            >
              Appliances will be kept {statusDropdownValue === 'On' ? "ON" : "OFF"} during this event
            </div>
            <Dropdown
              list={statusDropdownArr}
              value={statusDropdownValue}
              onSelect={statusDropdownClick}
              setIsDropdownOpen={setStatusDropdownOpen}
              isDropdownOpen={statusDropdownOpen}
              background="light"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              filled={true}
              label="Create event"
              onClick={onSubmit}
              size="medium"
              disabled={(
                eventName !== '' &&
                typeof startDate === 'object' &&
                typeof endDate === 'object' &&
                selectedOutlets.length !== 0
              ) ? false : true}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};