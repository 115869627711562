import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Modal,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel,
    //IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../App.css'; // Import your fonts
import {
    neutral800,
    primary500,
    primary600,
    primary700,
    white,
    neutral700,
    neutral500,
    primary580,
} from "../../lib/rebrandColors/colors";
import Dropdown from "../dropDown";
import TextField from "../textField";
import CustomButton from "../customButton";
import IconButton from "../iconButton";

export default function SpecialEventEdit({
    specialEventEditOpen,
    specialEventEditClose,
    specialEventEditSubmit,
    events,
    outlets,
    event = null,
}) {
    const [eventDate, setEventDate] = useState('');
    const [statusDropdownValue, setStatusDropdownValue] = useState('On');
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const statusDropdownClick = (item) => {
        setStatusDropdownValue(item);
    };
    const [statusDropdownArr, setStatusDropdownArr] = useState(['On', 'Off']);
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [eventName, setEventName] = useState('');
    useEffect(() => {
        if (event !== null) {
            setEventDate(event.date);
            setStatusDropdownValue(event.action);
            setSelectedOutlets(event.outlets);
            setEventName(event.title);
        };
    }, [event]);
    const onSubmit = () =>{
        const eventData ={
            date: eventDate,
            name: eventName,
            action: statusDropdownValue,
            outlets: selectedOutlets,
            id: event.id,
        };
        specialEventEditSubmit(eventData);
        setEventDate('');
        setEventName('');
        setSelectedOutlets([]);
        specialEventEditClose();
    };

    return (
        <Modal open={specialEventEditOpen} onClose={specialEventEditClose}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: '#FAFAFA',
                    borderRadius: "24px",
                    alignContent: "center",
                    width: 564,
                    height: 673,
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    // marginLeft: '40px',
                    gap: "20px",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <div
                            className="titleLarge"
                            style={{
                                color: '#1F1F1F',
                                textAlign: "Left",
                                width: '31.66%',
                            }}
                        >
                            Edit Event
                        </div>
                        <div
                            className="titleSmall"
                            style={{
                                color: '#1F1F1F',
                                textAlign: "Center",
                                width: '31.66%',
                                alignSelf: 'end',
                            }}
                        >
                            {eventDate}
                        </div>
                        <div style={{
                            display: 'flex',
                            width: '31.66%',
                            justifyContent: 'end',
                        }}>
                            <IconButton
                                iconName='cancel-1'
                                tone='dark'
                                size={24}
                                onClick={specialEventEditClose}
                            />
                        </div>
                    </div>
                    <TextField
                        label="Special Event Name *"
                        placeholder={eventName}
                        required
                        value={eventName}
                        onChange={setEventName}
                        size="medium"
                        background='light'
                    />
                    {/* Date Pickers */}
                    {/* <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '485px',
                        justifyContent: 'space-evenly'
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start date"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="End date"
                                value={endDate}
                                onChange={handleEndDateChange}
                            />
                        </LocalizationProvider>
                    </div> */}
                    <FormControl sx={{ width: "485px", marginTop: '10px' }}>
                        <InputLabel
                            id="outlet-label"
                            sx={{
                                color: neutral800,
                                "&.Mui-focused": {
                                    color: neutral800,
                                },
                                fontFamily: "IBM Plex Sans",
                            }}
                        >
                            Outlets
                        </InputLabel>
                        <Select
                            labelId="outlet-label"
                            multiple
                            value={selectedOutlets}
                            onChange={(e) => setSelectedOutlets(e.target.value)}
                            label="Outlets"
                            required
                            renderValue={(selected) =>
                                selected.length ? selected.join(", ") : "Select Outlets"
                            }
                            sx={{
                                backgroundColor: '#FAFAFA', //neutral700,
                                borderRadius: "34px",
                                marginTop: outlets && selectedOutlets.length > 0 ? "12px" : "0px",
                                border: '1px solid #999999',
                                color: '#999999',
                                "& .MuiSelect-icon": {
                                    color: '#999999',
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: '#999999',
                                },
                                "&.Mui-focused": {
                                    marginTop: outlets ? "12px" : "0px"
                                }
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: neutral800,
                                        "& .MuiMenuItem-root": {
                                            color: '#999999',
                                            "&:hover": {
                                                backgroundColor: primary500,
                                            },
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: primary700,
                                            "&:hover": {
                                                backgroundColor: primary700,
                                            },
                                        },
                                    },
                                },
                            }}
                        >
                            {outlets.map((outlet) => (
                                <MenuItem key={outlet.id} value={outlet.id}>
                                    {outlet.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <div
                            className="bodyMedium"
                            style={{ marginBottom: '10px' }}
                        >
                            Appliances will be kept {statusDropdownValue === 'On' ? "ON" : "OFF"} during this event
                        </div>
                        <Dropdown
                            list={statusDropdownArr}
                            value={statusDropdownValue}
                            onSelect={statusDropdownClick}
                            setIsDropdownOpen={setStatusDropdownOpen}
                            isDropdownOpen={statusDropdownOpen}
                            background="light"
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CustomButton
                            filled={true}
                            label="Update event"
                            onClick={onSubmit}
                            size="medium"
                            disabled={(
                                eventName !== '' &&
                                // typeof startDate === 'object' &&
                                // typeof endDate === 'object' &&
                                selectedOutlets.length !== 0
                            ) ? false : true}
                        />
                    </div>
                </div>
            </Box>
        </Modal>
    );
};