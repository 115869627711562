import React, { useEffect, useState } from "react";
import {
  fetchOrgID,
  fetchIsOverviewAccount,
  fetchOrganizationInfoFromID,
} from "../../functions/organization";
import { fetchOutlets, fetchProductTypes } from "../../functions/outletList";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import OrganizationPicker from "../../components/orgPicker";
import OutletList from "../../components/outletList";
import { fetchSavings } from "../../functions/savings";
import { useOutletContext } from "react-router-dom";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function ApplianceScreen() {
  // from layout
  const {
    orgID,
    orgName,
    isOverviewAccount,
    selectedOrgID,
    // child_orgs,
    timezone,
    allProdTypes,
    allOrgs,
    setTimezone,
    setRate,
    setSelectedOrgID,
    // setChild_Orgs
  } = useOutletContext();
  // const [orgID, setOrgID] = useState(localStorage.getItem("orgID") || "");
  // const [timezone, setTimezone] = useState(
  //   localStorage.getItem("timezone") || "UTC"
  // );
  const [child_orgs, setChild_Orgs] = useState(localStorage.getItem("child_orgs") || []);
  // const [orgName, setOrgName] = useState("");
  // const [rate, setRate] = useState(localStorage.getItem("rate") || "0.16");
  const [outlets, setOutlets] = useState([]);
  const [update, setUpdate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [wattHours, setWattHours] = useState([]);
  const [savings, setSavings] = useState([]);
  const [savingsLoading, setSavingsLoading] = useState(true);
  //const [prodTypes, setProdTypes] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [outletsLoading, setOutletsLoading] = useState(true);
  const [wattsLoading, setWattsLoading] = useState(true);
  const [averageUsage, setAverageUsage] = useState([]);
  const [averageLoading, setAverageLoading] = useState(true);
  // const [selectedOrgID, setSelectedOrgID] = useState(
  //   localStorage.getItem("selectedOrgID") || ""
  // );
  // const [allProdTypes, setAllProdTypes] = useState(localStorage.getItem("productTypes") || []);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  // const [isOverviewAccount, setIsOverviewAccount] = useState(localStorage.getItem("isOverviewAccount") === "true" || false);

  // This is for filtering
  const [selectedApplianceType, setSelectedApplianceType] = useState("");
  const handleApplianceTypeChange = (event) => {
    const selectedApplianceType = event.target.value;
    setSelectedApplianceType(selectedApplianceType);
    console.log("Fetching...");
    if (selectedOrgID !== "") {
      fetchWattHours(
        selectedOrgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    } else {
      fetchWattHours(
        orgID,
        setWattHours,
        setWattsLoading,
        null,
        selectedApplianceType
      );
    }
    console.log("Fetched!!");
  };

  // // Function to fetch data from Firestore
  // useEffect(() => {
  //   const fetchAndSetOrgID = async () => {
  //       if (orgID == "" || orgID == 'LZqI3R6MInUuwtgtROPK') {
  //           const {
  //               orgID: fetchedOrgID,
  //               timezone: fetchedTimezone,
  //               rate: fetchedRate,
  //               orgName: fetchedOrgName,
  //               child_orgs: fetchedChildOrgs,
  //           } = await fetchOrgID();
  //           if (fetchedOrgID) {
  //               setOrgID(fetchedOrgID);
  //               localStorage.setItem("orgID", fetchedOrgID);
  //               fetchIsOverviewAccount(fetchedOrgID, setIsOverviewAccount)
  //           };
  //           if (fetchedTimezone) {
  //               setTimezone(fetchedTimezone);
  //               localStorage.setItem("timezone", fetchedTimezone);
  //           };
  //           if (fetchedRate) {
  //               setRate(fetchedRate);
  //               localStorage.setItem("rate", fetchedRate);
  //           };
  //           if (fetchedOrgName) {
  //               setOrgName(fetchedOrgName);
  //               localStorage.setItem("orgName", setOrgName);
  //           };
  //           if (fetchedChildOrgs) {
  //               setChild_Orgs(fetchedChildOrgs);
  //               localStorage.setItem('child_orgs', fetchedChildOrgs);
  //           };
  //       };
  //   };
  //   fetchAndSetOrgID();
  // }, []);

  // get the following information from org id or selected org id
  // - child orgs, schedules, watt hours, tasks
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      //get the (selected) org document information from the firebase
      let orgData = []
      let orgNameArray = []
      if (selectedOrgID !== "") {
        orgData = await fetchOrganizationInfoFromID(selectedOrgID);
        setOrgData([orgData]);
      } else if (orgID !== "") {
        orgData = await fetchOrganizationInfoFromID(orgID);
        setOrgData([orgData]);
      };

      //if the (selected) org has child orgs make sure to update the child orgs in storage
      if (orgData.child_orgs) {
        setChild_Orgs(orgData.child_orgs);
        localStorage.setItem('child_orgs', orgData.child_orgs);
        if (orgData.child_orgs.length > 0) {
          const orgDataPromises = orgData.child_orgs.map(org => fetchOrganizationInfoFromID(org));
          const orgDataArray = await Promise.all(orgDataPromises);
          setOrgData(orgDataArray)
        };
      } else {
        setChild_Orgs([]);
        localStorage.setItem('child_orgs', []);
      };

      //get schedules and wattHours
      if (orgData.child_orgs) {
        if (orgData.child_orgs.length > 0) {

          fetchWattHours(orgData.child_orgs, setWattHours, setWattsLoading);
          fetchSavings(orgData.child_orgs, setSavings, setSavingsLoading);
          fetchAverageUsage(orgData.child_orgs, setAverageUsage, setAverageLoading);
        };
      } else if (selectedOrgID !== "") {
        fetchWattHours([selectedOrgID], setWattHours, setWattsLoading);
        fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
        fetchAverageUsage([selectedOrgID], setAverageUsage, setAverageLoading);
      } else {
        fetchWattHours([orgID], setWattHours, setWattsLoading);
        fetchSavings([orgID], setSavings, setSavingsLoading);
        fetchAverageUsage([orgID], setAverageUsage, setAverageLoading);
      };
    };
    fetchDashboardInfo();

  }, [selectedOrgID,]);

  // gets information upon orgID load if not an overview account
  // useEffect(() => {
  //   if (selectedOrgID !== "") {
  //     setWattsLoading(true);
  //     setOutletsLoading(true);
  //     const unsubscribe = fetchOutlets([selectedOrgID], timezone, setOutlets, setOutletsLoading, setProdTypes);
  //     fetchWattHours(selectedOrgID, setWattHours, setWattsLoading);
  //     setOutletsLoading(false);
  //     return () => unsubscribe();
  //   };
  // }, [selectedOrgID, timezone]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdate(!update)
    }, 120000); // 120000ms = 120 seconds
    return () => {
      clearInterval(interval);
    };
  }, [update]);

  useEffect(() => {
    setOutletsLoading(true);
    const fetchTimezone = localStorage.getItem('timezone');
    // NOTE: changed from timezone to fetched timezone as it wasn't updating correctly
    if (selectedOrgID !== "" && child_orgs.length == 0) {
      fetchOutlets([selectedOrgID], fetchTimezone, setOutlets, setOutletsLoading);
      fetchSavings([selectedOrgID], setSavings, setSavingsLoading);
    } else if (child_orgs.length > 0) {
      if (Array.isArray(child_orgs)) {
        fetchOutlets(child_orgs, fetchTimezone, setOutlets, setOutletsLoading);
        fetchSavings(child_orgs, setSavings, setSavingsLoading);
      } else {
        fetchOutlets(child_orgs.split(','), fetchTimezone, setOutlets, setOutletsLoading);
        fetchSavings(child_orgs.split(','), setSavings, setSavingsLoading);
      };
    } else {
      fetchOutlets([orgID], fetchTimezone, setOutlets, setOutletsLoading);
      fetchSavings([orgID], setSavings, setSavingsLoading);
    };
  }, [selectedOrgID, update, modalEdit, orgData]);

  // gets ALL product type info
  // useEffect(() => {
  //   if(allProdTypes.length==0){
  //     fetchProductTypes(setAllProdTypes); // Call fetchData function when component mounts
  //   }
  // }, []); // Empty dependency array ensures useEffect runs only once on mount

  // resize function useEffect
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render based on loading state
  return (
    <div style={{
      display: "flex",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1300px',
      justifyContent: 'center',
      marginTop: '86px'
    }}>
      <div
        style={{
          marginTop: '30px',
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
          <div className="headlineLarge"
            style={{
              width: "350px",
              height: "auto",
              display: "block",
              alignSelf: 'start',
              marginTop: '10px',
              color: '#FFFFFF',
            }}
          >
            Appliances
          </div>
          {(isOverviewAccount || orgID == 'LZqI3R6MInUuwtgtROPK') &&
            <OrganizationPicker
              allOrgs={allOrgs}
              orgID={orgID}
              selectedOrgID={selectedOrgID}
              setSelectedOrgID={setSelectedOrgID}
              setTimezone={setTimezone}
              setRate={setRate}
              label={true}
            />
          }
        </div>
        <div
          style={{
            marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
            display: "flex",
            flexDirection: "row",
            marginBottom: '30px'
          }}
        >
          <div
            style={{
              width: "900px",
              display: "flex",
              borderRadius: '24px',
              minHeight: '693px',
              backgroundColor: '#FAFAFA',
              maxHeight: '693px',
              overflowY: 'hidden',
              marginRight: '16px',
              justifyContent: 'center'
            }}
          >
            {!savingsLoading &&
              <OutletList
                outlets={outlets}
                setOutlets={setOutlets}
                width={'750px'}
                height={'625px'}
                wattHours={wattHours}
                savings={savings}
                orgData={orgData}
                loading={outletsLoading}
                prodTypes={allProdTypes}
                modalEdit={modalEdit}
                setModalEdit={setModalEdit}
                averageUsage={averageUsage}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
{/* {orgID !== "" &&
    <Header
      selectedOrgID={selectedOrgID}
      setSelectedOrgID={setSelectedOrgID}
      setTimezone={setTimezone}
      setRate={setRate}
      width={windowDimensions.width} 
      isOverviewAccount={isOverviewAccount}
    />
    } */}
{/* <div style={{
      minWidth: '100vw',
      minHeight: windowDimensions.height-86,
      backgroundColor: '#F2F4F8',
      marginTop: 86,
      }}>
      <div 
        style={{
          width: '100vw',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
      {wattsLoading || outletsLoading ? (
        <CircularProgress />)
      :
        <div
          style={{
            width: windowDimensions.width < 1400 ? "92.5vw" : "1300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
        {prodTypes && handleApplianceTypeChange && (
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems:'center',
          // width:'100%',
          //backgroundColor:'red',
        }}>
          <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems:'center',
              //backgroundColor:'red',
            }}>
            <FormHelperText style={{fontSize: windowDimensions.width < 1300 ? 12.5: 15, fontWeight:'500',}}>
              Filter data by product type:
            </FormHelperText>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <Select
                defaultValue={'All Outlets'}
                inputProps={{
                  name: 'age',
                  id: 'uncontrolled-native',
                }}
                onChange={handleApplianceTypeChange}
              >
                <MenuItem value="All Outlets">
                  <em>All Outlets</em>
                </MenuItem>
                {prodTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>)}
          <OutletListContainer
            outlets={
              selectedApplianceType &&
              selectedApplianceType != "" &&
              selectedApplianceType !== "All Outlets"
                ? selectedApplianceType == "N/A"
                  ? outlets.filter((outlet) => outlet.productType === "")
                  : outlets.filter(
                      (outlet) =>
                        outlet.productType === selectedApplianceType
                    )
                : outlets
            }
            prodTypes={allProdTypes}
            setOutlets={setOutlets}
            timezone={timezone}
            wattHours={wattHours}
            rate={rate}
            height={windowDimensions.height}
            width={windowDimensions.width}
          />
        </div>
      }
      </div>
    </div> */}