import { collection, getDocs, doc, deleteDoc, query, where, onSnapshot, updateDoc, arrayRemove } from "firebase/firestore";
import db from "../firebase";

const fetchAllOrgInfo = async (setOrgData) => {
  try {
    const orgQ = query(collection(db, 'organizations'));
    const orgSnapshot = await getDocs(orgQ);
    const organizations = [];

    for (const orgDoc of orgSnapshot.docs) {
      const orgData = orgDoc.data();
      organizations.push({
        orgID: orgData.uid,
        orgName: orgData.name,
        timezone: orgData.timezone,
      });
    };
    setOrgData(organizations);
  } catch (error) {
    console.error('Error fetching Organization Data ', error);
  };
};

const fetchAllOutletsSnapshot = (orgData, setOutlets) => {
  try {
    const q = query(collection(db, 'outlets'), where('orgID', '!=', null));
    const unsubscribeOutlets = onSnapshot(q, (outletSnapshot) => {
      const outlets = [];

      for (const outletDoc of outletSnapshot.docs) {
        const outletData = outletDoc.data();
        const name = outletData.name;
        const lastOffUpdate = outletData.lastOffUpdate;
        const lastOnUpdate = outletData.lastOnUpdate;
        const lastDataUpdate = outletData.lastDataUpdate;
        const orgID = outletData.orgID;
        const device = outletData.device

        const orgIndex = orgData.findIndex(obj => obj.orgID === orgID);
        const orgName = orgData[orgIndex]?.orgName || 'No Data';
        const timezone = orgData[orgIndex]?.timezone || 'UTC';

        const now = new Date();
        const nowTZ = new Date(now.toLocaleString('en-US', { timeZone: timezone }));
        const fiveMinutesAgo = new Date(nowTZ.getTime() - 5 * 60000);
        const oneWeekAgo = new Date(nowTZ.getDate() - 8);

        let wifiStatus = "Not activated";
        if (lastDataUpdate) {
          const lastUpdateDate = new Date(lastDataUpdate);
          if (lastUpdateDate >= fiveMinutesAgo) {
            wifiStatus = "Connected";
          } else {
            wifiStatus = "Disconnected";
          };
        };

        let status = (lastOnUpdate > lastOffUpdate || (!lastOnUpdate && !lastOffUpdate)) ? "On" : "Off";

        let scheduleStatus = "Not active";
        if (lastOnUpdate) {
          const lastOnUpdateDate = new Date(lastOnUpdate);
          if (lastOnUpdateDate >= oneWeekAgo) {
            scheduleStatus = "Active";
          };
        };

        outlets.push({
          ...outletData,
          outletId: outletDoc.id,
          device,
          orgName,
          orgID,
          name,
          lastOffUpdate,
          lastOnUpdate,
          lastDataUpdate,
          wifiStatus,
          status,
          scheduleStatus,
        });

        setOutlets(outlets);
      };

      return () => {
        unsubscribeOutlets();
      };
    });
  } catch (error) {
    console.error("Error fetching data: ", error);
    return null;
  };
};

const fetchAllOutletsInfo = async () => {
  try {
    const outletsRef = collection(db, "outlets");
    const q = query(outletsRef, where('orgID', '!=', null));
    const outletSnapshot = await getDocs(q);
    const outlets = [];
    const organizationsRef = collection(db, "organizations");
    const orgSnapshot = await getDocs(organizationsRef);
    const organizations = [];

    // put all org data into a dictionary to be sorted through once the outlet is known
    for (const orgDoc of orgSnapshot.docs) {
      const orgData = orgDoc.data();
      organizations.push({
        orgID: orgData.uid,
        orgName: orgData.name,
        timezone: orgData.timezone,
      });
    };

    // go through all outlets and retrieve their data
    for (const outletDoc of outletSnapshot.docs) {
      const outletData = outletDoc.data();

      const name = outletData.name;
      const lastOffUpdate = outletData.lastOffUpdate;
      const lastOnUpdate = outletData.lastOnUpdate;
      const lastDataUpdate = outletData.lastDataUpdate;
      const orgID = outletData.orgID;
      let orgName = "No data";

      // using the org information pulled earlier find the org name and timezone 
      // associated with the specific outlet
      const orgIndex = organizations.findIndex(obj => obj.orgID === orgID);
      orgName = organizations[orgIndex].orgName;
      const timezone = organizations[orgIndex].timezone || "UTC"; // timezone

      // Get the current date and time as a Date object
      const now = new Date();
      const nowTZ = new Date(now.toLocaleString('en-US', { timeZone: timezone }));

      // Subtract 5 minutes from the current time
      // Multiply minutes by 60000 to convert minutes to milliseconds
      const fiveMinutesAgo = new Date(nowTZ.getTime() - 5 * 60000);
      let wifiStatus = "Not activated";

      if (!lastDataUpdate || lastDataUpdate === "No data") {
        wifiStatus = "Not activated";
      } else {
        // Parse the lastDataUpdate string into a Date object
        const lastUpdateDate = new Date(lastDataUpdate);
        lastUpdateDate.toLocaleString('en-US', { timeZone: timezone });

        // Compare lastDataUpdate with five minutes ago
        if (lastUpdateDate >= fiveMinutesAgo) {
          wifiStatus = "Connected";
        } else {
          wifiStatus = "Disconnected";
        };
      };

      /*const docRef = doc(outletDoc.ref, "Status", "status");
      const docSnap = await getDoc(docRef);*/

      let status = "On";

      if (lastOnUpdate > lastOffUpdate) {
        status = "On"
      } else if (!lastOnUpdate && !lastOffUpdate) {
        status = "On"
      } else {
        status = "Off"
      };

      const oneWeekAgo = new Date(nowTZ.getDate() - 8);
      let scheduleStatus = "Not active";

      if (!lastOnUpdate) {
        scheduleStatus = "Not active";
      } else {
        // Parse the lastDataUpdate string into a Date object
        const lastOnUpdateDate = new Date(lastOnUpdate);
        lastOnUpdateDate.toLocaleString('en-US', { timeZone: timezone });

        // Compare lastDataUpdate with five minutes ago
        if (lastOnUpdateDate >= oneWeekAgo) {
          scheduleStatus = "Active";
        };
      };

      outlets.push({
        ...outletData,
        outletId: outletDoc.id,
        orgName,
        name,
        lastOffUpdate,
        lastOnUpdate,
        lastDataUpdate,
        wifiStatus,
        status,
        scheduleStatus,
      });
    };
    return outlets;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  };
};

// Function to delete the outlet from all necessary locations
const deleteOutletFromFirebase = async (outlet) => {
  try {
    // 1. Delete the outlet document from the outlets collection
    await deleteDoc(doc(db, "outlets", outlet.id));

    // 2. Delete the outlet from the organization's Outlets subcollection
    await deleteDoc(doc(db, `organizations/${outlet.organizationID}/Outlets`, outlet.outletID));

    // 3. Remove the outlet device from the devices array field in the organization document
    const orgDocRef = doc(db, "organizations", outlet.organizationID);
    await updateDoc(orgDocRef, {
      devices: arrayRemove(outlet.device),
    });

    // 4. Remove the outletID from the outletsSelected array field in any schedules document in the schedules collection
    const schedulesQuery = query(collection(db, "schedules"), where("outletsSelected", "array-contains", outlet.outletID));
    const schedulesSnapshot = await getDocs(schedulesQuery);
    schedulesSnapshot.forEach(async (scheduleDoc) => {
      await updateDoc(scheduleDoc.ref, {
        outletsSelected: arrayRemove(outlet.outletID),
      });
    });

    console.log(`Outlet ${outlet.id} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting outlet from Firebase:", error);
  }
};


export { fetchAllOutletsInfo, fetchAllOrgInfo, fetchAllOutletsSnapshot, deleteOutletFromFirebase };
