import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { neutral400, primary500 } from "../../lib/rebrandColors/colors";
import TimePicker from "../timePicker";
import WeeklyScheduleViewRecommended from "../weeklyScheduleViewRecommended";

export default function CalendarContainerRecommended({
  schedules,
  orgData,
  outlets,
  subOrgDropdownValue,
  role,
}) {
  // Filter schedules with status "new"
  const newSchedules = schedules?.filter(
    (schedule) => schedule.status === "New"
  );

  // Default view state
  const [view, setView] = useState("week");
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Handle view change
  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  // Update date based on TimePicker selection
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // NOTE: ensure that role==='view' cannot accept schedules

  return (
    <>
      {/* Only render the container if there are "new" schedules */}
      {newSchedules && newSchedules.length > 0 && (
        <>
          <Typography
            variant="h6"
            sx={{
              color: "#FFFFFF",
              fontFamily: "IBM Plex Mono",
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Recommended Schedules
          </Typography>
          <Box
            sx={{
              width: "1136px",
              height: "792px",
              padding: "24px",
              backgroundColor: "#F9F9F9",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* Tabs for View Selection */}
              <Tabs
                value={view}
                onChange={handleViewChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: primary500,
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    minWidth: "auto",
                    padding: "0 16px",
                    color: neutral400,
                    fontFamily: "IBM Plex Mono",
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: primary500,
                  },
                }}
              >
                <Tab label="Week" value="week" />
              </Tabs>
            </Box>

            {/* Time Picker */}
            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <TimePicker
                timeSpan={view.charAt(0).toUpperCase() + view.slice(1)}
                setTime={handleDateChange}
              />
            </Box>

            {/* Weekly View */}
            {view === "week" && (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "center",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                }}
              >
                <WeeklyScheduleViewRecommended
                  schedules={newSchedules}
                  org={subOrgDropdownValue ? subOrgDropdownValue : "All"}
                  outlets={outlets}
                  orgData={orgData}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}
