import {
  LOGIN_INIT,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CLEAR_LOGOUT_ERROR,
} from "../../constants/action-types";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

export const loginInit = () => ({ type: LOGIN_INIT });

export const loginError = (errorMessage) => ({
  type: LOGIN_ERROR,
  payload: { error: errorMessage },
});

export const loginSuccess = () => ({ type: LOGIN_SUCCESS });

export const clearAuthError = () => ({ type: CLEAR_LOGOUT_ERROR });
//need to ensure app state is populated prior to navigating to home page,
export function login(email, password, sign_in_method, setAlert) {
  return async function (dispatch, getState) {
    dispatch(loginInit());
    try {
      let authRecord = null;

      // email sign-in

      // console.log('email sign-in');
      //NOTE: ONly part that is needed
      authRecord = await signInWithEmailAndPassword(auth, email, password).then(
        console.log("signing in with: ", email)
      );
      window.localStorage.setItem("approvedSignIn", JSON.stringify(true));

    } catch (err) {
      console.error(err);
      if (err.code === "auth/multi-factor-auth-required") {
        setAlert({
          show: true,
          message: "Multi-Factor Authentication Required",
          severity: "error",
        });
        throw err
      } else if (err.code==="auth/network-request-failed"){
        console.log(err)
      } 
      else {
        //console.error(err);
        dispatch(loginError(err.message));
        setAlert({
          show: true,
          message: "Please verify that you entered the correct email and password",
          severity: "error",
        });
      }
    }
  };
}
