import React, { useState, useEffect } from "react";
import CustomButton from "../../components/customButton";
import CustomLogo from "../../components/logo";
import { white } from "../../lib/rebrandColors/colors";
import { useNavigate } from "react-router-dom";
import '../../App.css'; // Import your fonts
import { logout } from "../../firebase";
import { fetchGlobalSavings } from "../../functions/savings"

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height,
    };
};

export default function AwaitApproval() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const navigate = useNavigate();
    const [savings, setSavings] = useState({
        rev_co2eSavings: 0,
        rev_costSavings: 0,
        rev_energySavings: 0,
    })
    const text = "Plug in,\nstart saving.";
    
      useEffect(() => {
        const loadSavings = async () => {
          const fetchedSavings = await fetchGlobalSavings()
          setSavings(fetchedSavings)
        }
    
        loadSavings()
      }, [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        };


        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleClick = () => {
        logout();
        navigate('/login');
    };

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: '#1F1F1F',
                height: 'auto',
                minHeight: '100vh',
                minWidth: '1000px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div
                    style={{
                        display: "flex",
                        width: '50%',
                        minWidth: '550px',
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: 'center',
                        }}
                    >
                        <div style={{
                            width: "160px",
                            height: "auto",
                            display: "block",
                            alignSelf: 'start',
                            marginTop: '10px'
                        }}>
                            <CustomLogo
                                name="horizontal-negative"
                            />
                        </div>
                        <div
                            className="displayLarge"
                            style={{
                                color: white,
                                fontWeight: 700,
                                marginTop: '50px',
                                fontSize: "38px",
                                textAlign: "left",
                                alignSelf: 'start',
                            }}
                        >
                            Awaiting Account Activation
                        </div>
                        <div
                            className="bodyLarge"
                            style={{
                                color: '#B7B7B7',
                                fontSize: "16px",
                                textAlign: "left",
                                alignSelf: 'start',
                            }}
                        >
                            Please contact the Revert Technologies team to ensure your organization is activated!
                        </div>
                        <div style={{
                            alignSelf: 'start',
                            paddingTop: '20px',
                            marginBottom: '1%',
                        }}>
                            <CustomButton
                                size={"large"}
                                custFontSize={16}
                                filled={true}
                                label='Return'
                                iconName={'arrow-right'}
                                iconPosition="right"
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        alignSelf: 'center',
                        display: "flex",
                        height: '95vh',
                        minHeight: '400px',
                        width: '47.5%',
                        minWidth: '500px',
                        flexDirection: "column",
                        backgroundColor: 'white',
                        borderRadius: '20px',
                    }}
                    >
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        marginTop: '75px',
                        marginLeft: '45px'
                    }}>
                        <div className="displayLarge"
                        style={{ 
                            whiteSpace: 'pre-line', 
                            fontWeight:700, 
                            fontSize:'80px',
                            lineHeight: '104px',
                            color: '#2D2D2D',
                        }}
                        >
                        {text}
                        </div>
                        <div className='titleLarge'
                        style={{
                            color:'#666666',
                            marginTop:'150px'
                        }}
                        >
                        Across all customers
                        </div>
                        <div style={{
                        display:'flex',
                        flexDirection: 'row',
                        width:'95%',
                        justifyContent:'space-between',
                        marginTop:'22px',
                        }}
                        >
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div className="titleLarge"
                            style={{
                                color:'#666666',
                                fontSize:'20px',
                            }}
                            >
                            Energy Savings
                            </div>
                            <div style={{
                            display:'flex',
                            flexDirection: 'row',
                            }}
                            >
                            <div className="displayMedium"
                                style={{
                                fontWeight: '700',
                                color:'#999999',
                                }}
                            >
                            {(savings.rev_energySavings/1000000).toFixed(1)}
                            </div>
                            <div className="titleLarge" style={{
                                color:'#999999',
                                lineHeight:'70px',
                                marginLeft: '5px',
                                }}
                            >
                                mWh
                            </div>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div className="titleLarge"
                            style={{
                                color:'#666666',
                                fontSize:'20px',
                            }}
                            >
                            Bill Savings
                            </div>
                            <div className="displayMedium"
                                style={{
                                fontWeight: '700',
                                color:'#999999',
                                }}
                            >
                            ${(savings.rev_costSavings).toFixed(2)}
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div className="titleLarge"
                            style={{
                                color:'#666666',
                                fontSize:'20px',
                            }}
                            >
                            CO2e Savings
                            </div>
                            <div style={{
                            display:'flex',
                            flexDirection: 'row',
                            }}
                            >
                            <div className="displayMedium"
                                style={{
                                    fontWeight: '700',
                                    color:'#999999',
                                }}
                                >
                            {(savings.rev_co2eSavings/2000).toFixed(1)}
                            </div>
                            <div className="titleLarge" style={{
                                    color:'#999999',
                                    lineHeight:'70px',
                                    marginLeft: '5px',
                                    }}
                                >
                                    tons
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        </>
    );
};