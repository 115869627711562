import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/index.ts";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./pages/login";
import RegisterScreen from "./pages/registerUser/index.js";
import TestLogin from "./pages/login/testLogin";
import HomeScreen from "./pages/home/index.js";
import ScheduleScreen from "./pages/schedule/index.js";
import OutletsScreen from "./pages/admin-outlets/index.js";
import InventoryScreen from "./pages/inventory/index.js";
import UsersScreen from "./pages/users/index.js";
import PendingRoleScreen from "./pages/pending-role/index.js";
import "./assets/fonts/Manrope-VariableFont_wght.ttf"
import '@fontsource/roboto';
import OrganizationsScreen from "./pages/adminOrganizations/index.js";
import OrganizationRegisterScreen from "./pages/registerOrganization/index.js";
import AssignOutletsScreen from "./pages/assignOutlets/index.js";
import AccountDetails from "./pages/accountDetails/index.js";
import AppliancesScreen from "./pages/appliances/index.js";
import OTPScreen from "./pages/otpRegister/index.js";
import OTPLoginScreen from "./pages/otpLogin/index.js";
import TestScreen from './pages/testScreen/index.js';
import PasswordReset from "./pages/forgotPassword/index.js";
import AwaitApproval from "./pages/awaitApproval/index.js";
import Layout from './layout.js';
//import "./assets/fonts/Poppins-Medium.ttf"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          {/* <Route path='/testLogin' element={<TestLogin />} /> */}
          <Route path='/register' element={<RegisterScreen />} />
          {/*Routes with Header*/}
          <Route element={<Layout />}>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/appliances" element={<AppliancesScreen />} />
            <Route path="/schedules" element={<ScheduleScreen />} />
            <Route path="/outlets" element={<OutletsScreen />} />
            <Route path="/inventory" element={<InventoryScreen />} />
            <Route path="/users" element={<UsersScreen />} />
            <Route path="/organizations" element={<OrganizationsScreen />} />
            <Route path="/account" element={<AccountDetails />} />
            <Route path="/organizations/account" element={<AccountDetails />} />
            <Route path="/test" element={<TestScreen />} />
          </Route>
          <Route path="/pending-role" element={<PendingRoleScreen />} />
          <Route path="/organizations/register" element={<OrganizationRegisterScreen />} />
          <Route path="/organizations/await_approval" element={<AwaitApproval />} />
          <Route path="/organizations/register/outlets" element={<AssignOutletsScreen />} />
          <Route path="/otp" element={<OTPScreen />} />
          <Route path="/login/otp" element={<OTPLoginScreen />} />
          <Route path="/password/reset" element={<PasswordReset />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
