import React from "react";
import { Box, Typography } from "@mui/material";
import CustomIcon from "../icon";

export default function CustomEventCard({
  eventTitle,
  eventSubtitle,
  iconName,
  event,
  setIsEventEditModalOpen,
  setSelectedEvent,
}) {

  return (
    <Box
      sx={{
        display: "flex",
        width: "95%", // Use almost full width of the container (if we change this, the card gets shorter)
        padding: "4px 8px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "4px",
        backgroundColor: "#D5EDFF",
        boxSizing: "border-box", // Ensures padding is included within the width
        marginTop: "5px"
      }}
      onClick={() => {
        setIsEventEditModalOpen(true);
        setSelectedEvent(event);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          flex: 1, // Allows text to take the full remaining width
          minWidth: 0, // Required to ensure no overflow
        }}
      >
        {/* Event Title */}
        <Typography
          variant="subtitle2"
          noWrap
          sx={{
            color: "#2789FF",
            fontFamily: "IBM Plex Sans",
            fontSize: "10px",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          {eventTitle}
        </Typography>

        {/* Event Subtitle */}
        <Typography
          variant="caption"
          noWrap
          sx={{
            color: "#2789FF",
            fontFamily: "IBM Plex Sans",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          Outlets {event.action}
        </Typography>
      </Box>

      {/* Optional Icon */}
      {iconName && (
        <Box sx={{ flexShrink: 0, marginLeft: "8px" }}> {/* Ensures icon does not shrink */}
          <CustomIcon name={iconName} size={16} color="#2789FF" />
        </Box>
      )}
    </Box>
  );
}
