import React, { useState } from "react";
import {
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from "@mui/material";
import { deleteFullUser, updateUserRole, removeUserFromOrganization } from "../../functions/users";
import IconButton from "../iconButton";
import CustomButton from "../customButton";
import TextButton from "../textButton";
import { useNavigate } from "react-router-dom";

const UsersDataGridTable = ({
  user,
  data,
  loading,
  getUsers,
  orgID,
  role,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const roles = ["admin", "member", 'view'];
  const navigate = useNavigate();

  const handleDeleteClick = (org_user) => {
    setCurrentId(org_user.uid);
    setAssignDialogOpen(false);
    setDeleteDialogOpen(true);
  };

  const handleAssignClick = (org_user) => {
    setSelectedUser(org_user);
    setCurrentId(org_user.uid);
    setSelectedRole(org_user.role);
    setAssignDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    console.log(`Deleting user with id: ${currentId}`);
    await deleteFullUser(currentId)
    await removeUserFromOrganization(currentId, orgID);
    getUsers();
    setDeleteDialogOpen(false);
  };

  const handleAssignRoleConfirm = async () => {
    setAssignDialogOpen(false);
    console.log(currentId);
    console.log(selectedRole);
    try {
      await updateUserRole(currentId, selectedRole);
      await getUsers();
    } catch (error) {
      console.error("Error handling role assignment:", error);
    }
  };

  const handleAddClick = () => {
    navigate('/register', { state: { orgID: orgID } })
  }

  const infoContainerStyle = {
    marginTop: '16px',
    height: '72px',
    width: '480px',
    borderRadius: '16px',
    backgroundColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  //get the current users role
  const userRole = Object.values(data)
    .filter(item => item.uid === user.uid)
    .map(item => item.role);

  // console.log(data);

  return (
    <div>
      {data.map((org_user, index) => (
        <div
          style={infoContainerStyle}
          key={org_user.email}
        >
          <div
            className="titleMedium"
            style={{
              marginLeft: '24px',
              width: userRole != 'member' ? '260px' : '300px'
            }}
          >
            {org_user.email.length > 30 ? org_user.email.slice(0, 30) + '...' : org_user.email}
          </div>
          <div
            className="titleMedium"
            style={{
              marginLeft: '24px', width: '100px'
            }}
          >
            {org_user.role}
          </div>
          {userRole != 'member' && role != 'view' &&
            <IconButton
              iconName='more-vertical-circle-1'
              tone='light'
              onClick={() => handleAssignClick(org_user)}
            />
          }
        </div>
      ))}
      {userRole != 'member' && role != 'view' &&
        <div style={{
          marginTop: '24px',
          width: '480px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CustomButton
            label={'Add member'}
            filled={false}
            iconName={'add'}
            iconPosition="left"
            size="small"
            onClick={() => handleAddClick()}
          />
        </div>
      }
      <Dialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent style={{ width: '300px', }} >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Select
              style={{ width: '200px', marginRight: '50px' }}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              fullWidth
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
            <IconButton iconName='delete-2' tone='dark' onClick={() => handleDeleteClick(selectedUser)} />
          </div>
        </DialogContent>
        <DialogActions style={{ marginBottom: '20px' }}>
          <TextButton label='Cancel' onPress={() => setAssignDialogOpen(false)} />
          <TextButton label='Confirm' onPress={handleAssignRoleConfirm} />
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginBottom: '20px' }}>
          <TextButton label='Cancel' onPress={() => setDeleteDialogOpen(false)} />
          <TextButton label='Confirm' onPress={handleDeleteConfirm} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersDataGridTable;
