import React, { useState } from "react";
import { Box, InputBase, Typography, IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import {
  neutral200,
  neutral400,
  neutral500,
  neutral600,
  neutral700,
  orange300,
  white,
  black,
} from "../../lib/rebrandColors/colors";
import CustomIcon from "../icon";
import '../../App.css';

export default function TextField({
  label = "",
  placeholder = "placeholder text",
  descriptionText = "",
  search = false,
  visibility = false,
  //   highlight = false,
  size = "small",
  onChange = null,
  textError = false,
  incomingValue,
  background = 'dark',
  disabled = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(incomingValue || "");
  const [isFocused, setIsFocused] = useState(false);


  // Define width based on the size parameter
  const sizes = {
    extraSmall: '150px',
    small: "250px",
    medium: "450px",
    large: "650px",
  };

  // Get width based on size
  const width = sizes[size] || sizes.medium;

  // Function to toggle visibility icon (if visibility is true)
  const handleVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update the input value

    if (onChange) onChange(event.target.value);
  };

  return (
    <div
      style={{
        //width,
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Label */}
      <div
        className="labelMedium"
        style={{
          color: background === 'dark' ? neutral400 : '#666666',
          paddingLeft: '20px',
          fontSize: '10px',
        }}
      >
        {label}
      </div>

      {/* Input Box with optional search and visibility icons */}
      <Box
        sx={{
          display: "flex",
          height: "24px",
          width: width,
          alignItems: "center",
          backgroundColor: background === 'dark' ? '#2D2D2D' : '#FAFAFA',
          borderRadius: "40px",
          padding: "12px 16px",
          border: `1px solid ${isFocused ? "#FF0004" : background === 'dark' ? "transparent" : '#999999'}`,
          gap: "8px"
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {/* Search Icon */}
        {search && (
          <CustomIcon name="search-1" color={white} size={24} />
        )}

        {/* Input Field */}
        <InputBase
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          className={`label${size.charAt(0).toUpperCase()}${size.slice(1)}`}
          sx={{
            flexGrow: 1,
            color: background === 'dark' ? inputValue ? white : neutral400 : black,
            fontFamily: "IBM Plex Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px" /* 150% */,
            cursor: disabled ? 'not-allowed' : 'default'
          }}
          type={visibility && !showPassword ? "password" : "text"}
          disabled={disabled}
        />

        {/* Visibility Icon */}
        {visibility && (
          <IconButton
            onClick={handleVisibilityToggle}
            sx={{ color: neutral400, padding: "0 8px" }}
          >
            <CustomIcon
              name={showPassword ? "view-off-slash" : "view"}
              color={neutral200}
              size={24}
            />
          </IconButton>
        )}
      </Box>

      {/* Description Text */}
      {descriptionText && (
        <Typography
          className={`label${size.charAt(0).toUpperCase()}${size.slice(1)}`}
          variant="caption"
          sx={{
            color: textError ? orange300 : isFocused ? "#FF0004" : neutral500,
            marginTop: "8px",
            paddingLeft: search ? "30px" : "16px", // Align with search icon or input
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%" /* 16.8px */,
          }}
        >
          {descriptionText}
        </Typography>
      )}
    </div>
  );
}
