import React, { useState, useEffect } from "react";
import {
    Modal,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    CircularProgress,
} from "@mui/material";
import '../../App.css'; // Import your fonts
import {
    neutral800,
    primary500,
    primary600,
    primary700,
    white,
    neutral700,
    neutral500,
    primary580,
    black,
    grey600,
    neutral400,
    elevationLight1,
    neutral300,
} from "../../lib/rebrandColors/colors";
import Dropdown from "../dropDown";
import TextField from "../textField";
import CustomButton from "../customButton";
import IconButton from "../iconButton";
import CustomIcon from "../icon";
import TextButton from "../textButton";


export default function EditForm({
    open,
    schedules,
    handleClose,
    handleSubmit,
    isOverviewAccount,
    child_org_data,
    organizationID,
    organizationName,
    outlets,
    schedule = 'none',
    setClickedSchedule,
}) {
    const [selectedSchedule, setSelectedSchedule] = useState(schedule);
    const [productTypesArr, setProductTypesArr] = useState([]);
    const [missionId, setMissionId] = useState('');
    const [onHour, setOnHour] = useState('');
    const [onMinute, setOnMinute] = useState('');
    const [offHour, setOffHour] = useState('');
    const [offMinute, setOffMinute] = useState('');
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [repeat, setRepeat] = useState([]);

    const [offAMPMDropdownValue, setOffAMPMDropdownValue] = useState('AM');
    const [offAMPMDropdownOpen, setOffAMPMDropdownOpen] = useState(false);
    const offAMPMDropdownClick = (item) => {
        setOffAMPMDropdownValue(item);
    };
    const [offAMPMDropdownArr, setOffAMPMDropdownArr] = useState(['AM', 'PM']);

    const [onAMPMDropdownValue, setOnAMPMDropdownValue] = useState('AM');
    const [onAMPMDropdownOpen, setOnAMPMDropdownOpen] = useState(false);
    const onAMPMDropdownClick = (item) => {
        // console.log('item', item);
        setOnAMPMDropdownValue(item);
    };
    const [onAMPMDropdownArr, setOnAMPMDropdownArr] = useState(['AM', 'PM']);

    const weekArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const toggleDay = (day) => {
        setRepeat((prevRepeat) =>
            prevRepeat.includes(day)
                ? prevRepeat.filter((d) => d !== day) // Remove day if it exists
                : [...prevRepeat, day] // Add day if it doesn't exist
        );
    };

    const [missionIdStart, setMissionIdStart] = useState('');
    const [onHourStart, setOnHourStart] = useState('');
    const [onMinuteStart, setOnMinuteStart] = useState('');
    const [offHourStart, setOffHourStart] = useState('');
    const [offMinuteStart, setOffMinuteStart] = useState('');
    const [selectedOutletsStart, setSelectedOutletsStart] = useState([]);
    const [repeatStart, setRepeatStart] = useState([]);
    const [onAMPMDropdownValueStart, setOnAMPMDropdownValueStart] = useState();
    const [offAMPMDropdownValueStart, setOffAMPMDropdownValueStart] = useState();

    // product types
    useEffect(() => {
        const newProductTypesArr = schedules.map((schedule) => {
            const productTypes = schedule.outletsSelected
                .map(outlet => outlets.find(obj => obj.id === outlet)?.productType)
                .filter(Boolean);
            return [...new Set(productTypes)];
        });
        setProductTypesArr(newProductTypesArr);
    }, [schedules, outlets]);

    // sets schedule details
    useEffect(() => {
        if (schedule !== 'none') {
            setSelectedSchedule(schedule)
        }
        if (selectedSchedule !== 'none') {
            setMissionId(
                selectedSchedule.missionId !== '' ?
                    selectedSchedule.missionId :
                    child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
            );
            setOnHour(selectedSchedule.onHour);
            setOnMinute(selectedSchedule.onMinute);
            setOffHour(selectedSchedule.offHour);
            setOffMinute(selectedSchedule.offMinute);
            setOffAMPMDropdownValue(selectedSchedule.offHour > 12 ? 'PM' : 'AM');
            setOnAMPMDropdownValue(selectedSchedule.onHour > 12 ? 'PM' : 'AM');
            setSelectedOutlets(selectedSchedule.outletsSelected);
            setRepeat(selectedSchedule.repeat);

            // hold initial values for comparison
            setMissionIdStart(
                selectedSchedule.missionId !== '' ?
                    selectedSchedule.missionId :
                    child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
            );
            setOnHourStart(selectedSchedule.onHour);
            setOnMinuteStart(selectedSchedule.onMinute);
            setOffHourStart(selectedSchedule.offHour);
            setOffMinuteStart(selectedSchedule.offMinute);
            setOffAMPMDropdownValueStart(selectedSchedule.offHour > 12 ? 'PM' : 'AM');
            setOnAMPMDropdownValueStart(selectedSchedule.onHour > 12 ? 'PM' : 'AM');
            setSelectedOutletsStart(selectedSchedule.outletsSelected);
            setRepeatStart(selectedSchedule.repeat);
        };
    }, [selectedSchedule, schedule]);

    const onSubmit = () => {
        // id for firebase doc
        console.log('submit test', selectedSchedule.id);

        let newOnHour = Number(onHour);
        if (onHour === 12 && onAMPMDropdownValue === 'AM') {
            newOnHour = 0;
        } else if (onHour < 12 && onAMPMDropdownValue === 'PM') {
            newOnHour = Number(onHour) + 12;
        };
        const newOnMinute = Number(onMinute);

        let newOffHour = Number(offHour);
        if (offHour === 12 && offAMPMDropdownValue === 'AM') {
            newOffHour = 0;
        } else if (offHour < 12 && offAMPMDropdownValue === 'PM') {
            newOffHour = Number(offHour) + 12;
        };
        const newOffMinute = Number(offMinute);

        const newRepeat = repeat.sort((a, b) => weekArr.indexOf(a) - weekArr.indexOf(b));
        //NOTE: field in schedule doc is outletsSelected
        const newSelectedOutlets = selectedOutlets;
        const newMissionId = missionId;

        const eventData = {
            id: selectedSchedule.id,
            missionId: newMissionId,
            offHour: newOffHour,
            offMinute: newOffMinute,
            onHour: newOnHour,
            onMinute: newOnMinute,
            outletsSelected: newSelectedOutlets,
            repeat: newRepeat,
        };
        handleSubmit(eventData);
        setSelectedSchedule('none');
        setClickedSchedule('none');
        handleClose();
    };
    const onCancel = () => {
        setMissionId(missionIdStart);
        setOnHour(onHourStart);
        setOnMinute(onMinuteStart);
        setOffHour(offHourStart);
        setOffMinute(offMinuteStart);
        setOffAMPMDropdownValue(offAMPMDropdownValueStart);
        setOnAMPMDropdownValue(onAMPMDropdownValueStart);
        setSelectedOutlets(selectedOutletsStart);
        setRepeat(repeatStart);
        setSelectedSchedule('none');
        setClickedSchedule('none');
        handleClose();
    };
    

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: '#FAFAFA',
                    borderRadius: "24px",
                    alignContent: "center",
                    width: 564,
                    height: 700,
                    overflowY: 'scroll',
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '40px',
                    // marginLeft: '40px',
                    gap: "20px",
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowY: 'scroll',
                }}>
                    <div style={{
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <div
                            className="titleLarge"
                            style={{ color: '#1F1F1F', textAlign: "Left" }}
                        >
                            Select a Schedule to Edit
                        </div>
                        <IconButton
                            iconName='cancel-1'
                            tone='dark'
                            size={24}
                            onClick={handleClose}
                            disabled={
                                (
                                    onHour !== onHourStart ||
                                    onMinute !== onMinuteStart ||
                                    offHour !== offHourStart ||
                                    offMinute !== offMinuteStart ||
                                    onAMPMDropdownValue !== onAMPMDropdownValueStart ||
                                    offAMPMDropdownValue !== offAMPMDropdownValueStart ||
                                    repeat !== repeatStart ||
                                    selectedOutlets !== selectedOutletsStart ||
                                    missionId !== missionIdStart
                                ) && selectedSchedule !== 'none' ? true : false
                            }
                        />
                    </div>
                    {selectedSchedule === 'none' &&
                        (schedules.length > 0 && productTypesArr?.length > 0 ?
                            schedules.map((schedule, i) => {
                                return (
                                    <div>
                                        <div
                                            key={schedule.id}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '500px',
                                                height: '68px',
                                                alignItems: 'center',
                                                boxShadow: elevationLight1,
                                                borderRadius: '16px',
                                                border: `1px solid ${neutral300}`,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setSelectedSchedule(schedule)}
                                        >
                                            <div style={{
                                                height: '48px',
                                                width: '58px',
                                                borderRadius: '50px',
                                                backgroundColor: '#F1F1F1',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginLeft: '5px'
                                            }}>
                                                <CustomIcon
                                                    name="building-1"
                                                    color={black}
                                                    size={24}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                marginLeft: '10px'
                                            }}>
                                                <div
                                                    className="labelMedium"
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '700',
                                                        color: grey600
                                                    }}
                                                >
                                                    {schedule.missionId !== '' ?
                                                        schedule.missionId
                                                        : child_org_data.find(org => org.uid === schedule.orgID)?.name
                                                    }
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'start',
                                                    width: '100%',
                                                    marginTop: '3px'
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'start',
                                                        width: '97%',
                                                        overflow: 'hidden',
                                                    }}>
                                                        {productTypesArr[i]?.length > 0 ?
                                                            productTypesArr[i].map((prodType, j) => {
                                                                return (
                                                                    <div
                                                                        className="labelLarge"
                                                                        key={`${productTypesArr[i]}${j}`}
                                                                        style={{
                                                                            fontSize: productTypesArr[i].length > 2 ? '10px' : '12px',
                                                                            marginRight: '2%',
                                                                            color: neutral400,
                                                                            lineHeight: '10px',
                                                                        }}
                                                                    >
                                                                        {prodType}
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '50px'
                                                            }}>
                                                                <CircularProgress />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div
                                                        className="labelSmall"
                                                        style={{
                                                            color: neutral400,
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <div style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                                Off:
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.offHour > 12 ?
                                                                        schedule.offHour - 12 :
                                                                        schedule.offHour === 0 ?
                                                                            12 : schedule.offHour
                                                                }
                                                            </div>
                                                            <div>
                                                                :
                                                            </div>
                                                            <div>
                                                                {schedule.offMinute.toString().length !== 2
                                                                    ? `0${schedule.offMinute}`
                                                                    : schedule.offMinute
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            marginLeft: '5px',
                                                        }}>
                                                            <div style={{
                                                                paddingRight: '2px',
                                                            }}>
                                                                On:
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.onHour > 12 ?
                                                                        schedule.onHour - 12 :
                                                                        schedule.onHour === 0 ?
                                                                            12 : schedule.onHour
                                                                }
                                                            </div>
                                                            <div>
                                                                :
                                                            </div>
                                                            <div>
                                                                {
                                                                    schedule.onMinute.toString().length !== 2
                                                                        ? `0${schedule.onMinute}`
                                                                        : schedule.onMinute
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '50px'
                                }}
                                className="headlineSmall"
                            >
                                No schedules today
                            </div>
                        )
                    }
                    {selectedSchedule !== 'none' &&
                        <div>
                            <div style={{
                                alignSelf: 'start',
                                marginTop: '-10px',
                            }}>
                                <IconButton
                                    iconName="arrow-left"
                                    onClick={() => {
                                        setSelectedSchedule('none')
                                        setClickedSchedule('none')
                                    }}
                                    buttonType="none"
                                    tone="light"
                                    disabled={
                                        (
                                            onHour !== onHourStart ||
                                            onMinute !== onMinuteStart ||
                                            offHour !== offHourStart ||
                                            offMinute !== offMinuteStart ||
                                            onAMPMDropdownValue !== onAMPMDropdownValueStart ||
                                            offAMPMDropdownValue !== offAMPMDropdownValueStart ||
                                            repeat !== repeatStart ||
                                            selectedOutlets !== selectedOutletsStart ||
                                            missionId !== missionIdStart
                                        ) ? true : false
                                    }
                                />
                            </div>
                            <TextField
                                label="Schedule Name"
                                placeholder={
                                    selectedSchedule.missionId !== '' ?
                                        selectedSchedule.missionId :
                                        child_org_data.find(org => org.uid === selectedSchedule.orgID)?.name
                                }
                                required
                                value={missionId}
                                onChange={setMissionId}
                                size="medium"
                                background='light'
                            />
                            {/* on block */}
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <TextField
                                    label="On Hour"
                                    placeholder={selectedSchedule.onHour > 12 ? selectedSchedule.onHour - 12 : selectedSchedule.onHour === 0 ? 12 : selectedSchedule.onHour}
                                    required
                                    value={onHour}
                                    onChange={setOnHour}
                                    size="extraSmall"
                                    background='light'
                                />
                                <div style={{
                                    paddingLeft: '2px',
                                    paddingRight: '2px',
                                    paddingTop: '10px',
                                }}>
                                    :
                                </div>
                                <TextField
                                    label="On Minute"
                                    placeholder={selectedSchedule.onMinute.toString().length !== 2 ?
                                        `0${selectedSchedule.onMinute}` : selectedSchedule.onMinute
                                    }
                                    required
                                    value={onMinute}
                                    onChange={setOnMinute}
                                    size="extraSmall"
                                    background='light'
                                />
                                <div style={{ paddingLeft: '2px', }}>
                                    <Dropdown
                                        list={onAMPMDropdownArr}
                                        value={onAMPMDropdownValue}
                                        onSelect={onAMPMDropdownClick}
                                        setIsDropdownOpen={setOnAMPMDropdownOpen}
                                        isDropdownOpen={onAMPMDropdownOpen}
                                        label={'AM/PM'}
                                        size={75}
                                        background="light"
                                    />
                                </div>
                            </div>
                            {/* off block */}
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <TextField
                                    label="Off Hour"
                                    placeholder={selectedSchedule.offHour > 12 ? selectedSchedule.offHour - 12 : selectedSchedule.offHour === 0 ? 12 : selectedSchedule.offHour}
                                    required
                                    value={offHour}
                                    onChange={setOffHour}
                                    size="extraSmall"
                                    background='light'
                                />
                                <div style={{
                                    paddingLeft: '2px',
                                    paddingRight: '2px',
                                    paddingTop: '10px',
                                }}>
                                    :
                                </div>
                                <TextField
                                    label="Off Minute"
                                    placeholder={selectedSchedule.offMinute.toString().length !== 2 ?
                                        `0${selectedSchedule.offMinute}` : selectedSchedule.offMinute
                                    }
                                    required
                                    value={offMinute}
                                    onChange={setOffMinute}
                                    size="extraSmall"
                                    background='light'
                                />
                                <div style={{ paddingLeft: '2px', }}>
                                    <Dropdown
                                        list={offAMPMDropdownArr}
                                        value={offAMPMDropdownValue}
                                        onSelect={offAMPMDropdownClick}
                                        setIsDropdownOpen={setOffAMPMDropdownOpen}
                                        isDropdownOpen={offAMPMDropdownOpen}
                                        label={'AM/PM'}
                                        size={75}
                                        background="light"
                                    />
                                </div>
                            </div>
                            {/* outlets block */}
                            <FormControl sx={{ width: "485px", marginTop: '10px' }}>
                                <InputLabel
                                    id="outlet-label"
                                    sx={{
                                        color: neutral800,
                                        "&.Mui-focused": {
                                            color: neutral800,
                                        },
                                        fontFamily: "IBM Plex Sans",
                                    }}
                                >
                                    Selected Outlets
                                </InputLabel>
                                <Select
                                    labelId="outlet-label"
                                    multiple
                                    value={selectedOutlets}
                                    onChange={(e) => setSelectedOutlets(e.target.value)}
                                    label="Outlets"
                                    required
                                    renderValue={(selected) =>
                                        selected.length ? selected.join(", ") : "Select Outlets"
                                    }
                                    sx={{
                                        backgroundColor: '#FAFAFA', //neutral700,
                                        borderRadius: "34px",
                                        marginTop: outlets && selectedOutlets.length > 0 ? "12px" : "0px",
                                        border: '1px solid #999999',
                                        color: '#999999',
                                        "& .MuiSelect-icon": {
                                            color: '#999999',
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: '#999999',
                                        },
                                        "&.Mui-focused": {
                                            marginTop: outlets ? "12px" : "0px"
                                        }
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: neutral800,
                                                "& .MuiMenuItem-root": {
                                                    color: '#999999',
                                                    "&:hover": {
                                                        backgroundColor: primary500,
                                                    },
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: primary700,
                                                    "&:hover": {
                                                        backgroundColor: primary700,
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {outlets.map((outlet) => (
                                        <MenuItem key={outlet.id} value={outlet.id}>
                                            {outlet.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* repeat block */}
                            <div style={{
                                marginTop: '25px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <div
                                    className="labelMedium"
                                    style={{ color: neutral800 }}
                                >
                                    Days:
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    marginTop: '10px',
                                }}>
                                    {weekArr.map(day => {
                                        return (
                                            <div
                                                key={day}
                                                onClick={() => toggleDay(day)}
                                                style={{
                                                    borderWidth: '1px',
                                                    borderColor: repeat.includes(day) ? primary500 : neutral800,
                                                    borderStyle: 'solid',
                                                    width: '50px',
                                                    height: '50px',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    color: repeat.includes(day) ? primary500 : neutral800,
                                                }}
                                                className="buttonMedium"
                                            >
                                                {day}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                    {selectedSchedule !== 'none' &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                filled={true}
                                label="Edit Schedule"
                                onClick={onSubmit}
                                size="medium"
                                disabled={(
                                    onHour !== onHourStart ||
                                    onMinute !== onMinuteStart ||
                                    offHour !== offHourStart ||
                                    offMinute !== offMinuteStart ||
                                    onAMPMDropdownValue !== onAMPMDropdownValueStart ||
                                    offAMPMDropdownValue !== offAMPMDropdownValueStart ||
                                    repeat !== repeatStart ||
                                    selectedOutlets !== selectedOutletsStart ||
                                    missionId !== missionIdStart
                                ) ? false : true}
                            />
                        </div>
                    }
                    {selectedSchedule !== 'none' &&
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextButton
                                label="Cancel"
                                onPress={onCancel}
                                size="small"
                                disabled={(
                                    onHour !== onHourStart ||
                                    onMinute !== onMinuteStart ||
                                    offHour !== offHourStart ||
                                    offMinute !== offMinuteStart ||
                                    onAMPMDropdownValue !== onAMPMDropdownValueStart ||
                                    offAMPMDropdownValue !== offAMPMDropdownValueStart ||
                                    repeat !== repeatStart ||
                                    selectedOutlets !== selectedOutletsStart ||
                                    missionId !== missionIdStart
                                ) ? false : true}
                            />
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    );
};