import {
  collection,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc
} from "firebase/firestore";
import db from "../firebase";

export const fetchLifetimeSavings = async (
  orgID,
  setLifeCO2eSavings,
  setLifeCostSavings,
  setLifeEnergySavings,
  rate
) => {
  const getLifetimeSavings = async () => {
    const lifetimeQ = query(
      collection(db, "organizations"),
      where("uid", "==", orgID)
    );

    const lifetimeSnapshot = await getDocs(lifetimeQ);
    lifetimeSnapshot.forEach((doc) => {
      setLifeCO2eSavings(doc.data()["rev_co2eSavings"]);
      setLifeCostSavings((doc.data()["rev_energySavings"] / 1000) * rate);
      setLifeEnergySavings(doc.data()["rev_energySavings"]);
    });
  };
  getLifetimeSavings();
};

export const fetchMonthlySavings = async (
  orgID,
  setMonthlyCO2eSavings,
  setMonthlyCostSavings,
  setMonthlyEnergySavings,
  year,
  month,
  rate,
  outletID = null
) => {
  const getMonthlySavings = async () => {
    const co2eSavingsArray = [];
    const costSavingsArray = [];
    const energySavingsArray = [];
    let monthQ;
    if (outletID) {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`),
        where("outletID", "==", outletID)
      );
    } else {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`)
      );
    }
    const monthSnapshot = await getDocs(monthQ);
    monthSnapshot.forEach((doc) => {
      co2eSavingsArray.push({
        [doc.data().outletID]: doc.data().co2eSavings,
      });
      costSavingsArray.push({
        [doc.data().outletID]: (doc.data().energySavings / 1000) * rate,
      });
      energySavingsArray.push({
        [doc.data().outletID]: doc.data().energySavings,
      });
    });
    setMonthlyCO2eSavings(co2eSavingsArray);
    setMonthlyCostSavings(costSavingsArray);
    setMonthlyEnergySavings(energySavingsArray);
  };
  getMonthlySavings();
};

// This function was only used once, to update the document one time, but I will leave it here, just in case
export const fetchTotalSavingsAndUpdate = async () => {
  const getTotalSavings = async () => {
    const savingsSnapshot = await getDocs(collection(db, "savings"));

    let totalCO2eSavings = 0;
    let totalCostSavings = 0;
    let totalEnergySavings = 0;

    savingsSnapshot.forEach((doc) => {
      const data = doc.data();
      totalCO2eSavings += data.co2eSavings || 0;
      totalCostSavings += data.costSavings || 0;
      totalEnergySavings += data.energySavings || 0;
    });

    // Update the global/organizations document
    const orgDocRef = doc(db, "global", "organizations");
    await updateDoc(orgDocRef, {
      rev_co2eSavings: totalCO2eSavings,
      rev_costSavings: totalCostSavings,
      rev_energySavings: totalEnergySavings,
    });

    // Return the accumulated totals as a JSON object
    return {
      rev_co2eSavings: totalCO2eSavings,
      rev_costSavings: totalCostSavings,
      rev_energySavings: totalEnergySavings,
    };
  };

  return await getTotalSavings();
};

export const fetchGlobalSavings = async () => {
  const getSavings = async () => {
    const orgDocRef = doc(db, "global", "organizations");
    const orgDocSnapshot = await getDoc(orgDocRef);

    if (orgDocSnapshot.exists()) {
      const data = orgDocSnapshot.data();
      return {
        rev_co2eSavings: data.rev_co2eSavings || 0,
        rev_costSavings: data.rev_costSavings || 0,
        rev_energySavings: data.rev_energySavings || 0,
      };
    } else {
      // Handle case where document does not exist
      return {
        rev_co2eSavings: 0,
        rev_costSavings: 0,
        rev_energySavings: 0,
      };
    };
  };

  return await getSavings();
};

export const fetchOutletSavings = async (outletID) => {
  const getSavings = async () => {
    let co2eSavings = 0;
    let costSavings = 0;
    let energySavings = 0;
    const outletQ = query(collection(db, 'savings'), where('outletID', '==', outletID));
    const outletSnapshot = await getDocs(outletQ);

    outletSnapshot.forEach((doc) => {
      co2eSavings += doc.data().co2eSavings
      costSavings += doc.data().costSavings//(doc.data().energySavings / 1000) * rate,
      energySavings += doc.data().energySavings
    });
    return [co2eSavings, costSavings, energySavings]
  };

  return await getSavings();
};

export const fetchSavings = (
  orgIDs,
  setSavings,
  setSavingsLoading,
  outletID = null,
  applianceType = null
) => {
  let savings = [];
  let savingsQ;
  //get new wattHours collection
  const getSavings = async () => {
    if (outletID) {
      // ----------------> If there is an outletID, we need to filter
      savingsQ = query(
        collection(db, "savings"),
        where("orgID", 'in', orgIDs),
        where("outletID", "==", outletID)
      );
    } else {
      savingsQ = query(
        collection(db, "savings"),
        where("orgID", 'in', orgIDs),
      );
    };
    const savingsQuerySnapshot = await getDocs(savingsQ);

    savingsQuerySnapshot.forEach((doc) => {
      savings.push(doc.data());
    });

    setSavings(savings);
    setSavingsLoading(false);
    // console.log('savings function', savings);
  };
  getSavings();
};