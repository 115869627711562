import React, { useState } from "react";
import { ReactComponent as dashboardSquare } from "../../assets/icons/dashboard-square.svg";
import { ReactComponent as add } from "../../assets/icons/add.svg";
import { ReactComponent as arrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as arrowRight1 } from "../../assets/icons/arrow-right-1.svg";
import { ReactComponent as arrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as building1 } from "../../assets/icons/building-1.svg";
import { ReactComponent as building } from "../../assets/icons/building.svg";
import { ReactComponent as calendar1 } from "../../assets/icons/calendar-1.svg";
import { ReactComponent as calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as connect } from "../../assets/icons/connect.svg";
import { ReactComponent as menu1 } from "../../assets/icons/menu-1.svg";
import { ReactComponent as notification } from "../../assets/icons/notification.svg";
import { ReactComponent as pencilEdit } from "../../assets/icons/pencil-edit.svg";
import { ReactComponent as search1 } from "../../assets/icons/search-1.svg";
import { ReactComponent as task1 } from "../../assets/icons/task-1.svg";
import { ReactComponent as task } from "../../assets/icons/task.svg";
import { ReactComponent as userIcon } from "../../assets/icons/user-icon.svg";
import { ReactComponent as viewOffSlash } from "../../assets/icons/view-off-slash.svg";
import { ReactComponent as viewOff } from "../../assets/icons/view-off.svg";
import { ReactComponent as view } from "../../assets/icons/view.svg";
import { ReactComponent as dashboardSquare1 } from "../../assets/icons/dashboard-square-1.svg";
import { ReactComponent as arrowDown1 } from "../../assets/icons/arrow-down-1.svg";
import { ReactComponent as arrowUp1 } from "../../assets/icons/arrow-up-1.svg";
import { ReactComponent as cancel1 } from '../../assets/icons/cancel-01.svg';
import { ReactComponent as delete2 } from '../../assets/icons/delete-02.svg';
import { ReactComponent as cloudUpload } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as verticalCircle } from '../../assets/icons/more-vertical-circle-1.svg';
import { ReactComponent as plug } from '../../assets/icons/plug-01.svg';
import { ReactComponent as clock } from '../../assets/icons/clock-01.svg';
import { white } from "../../lib/rebrandColors/colors";

const icons = {
    'dashboard-square': dashboardSquare,
    'add': add,
    'arrow-left': arrowLeft,
    'arrow-right-1': arrowRight1,
    'arrow-right': arrowRight,
    'building-1': building1,
    'building': building,
    'calendar-1': calendar1,
    'calendar': calendar,
    'connect': connect,
    'menu-1': menu1,
    'notification': notification,
    'pencil-edit': pencilEdit,
    'search-1': search1,
    'task-1': task1,
    'task': task,
    'user-icon': userIcon,
    'view-off-slash': viewOffSlash,
    'view-off': viewOff,
    'view': view,
    'dashboard-square-1': dashboardSquare1,
    'arrow-down-1': arrowDown1,
    'arrow-up-1': arrowUp1,
    'cancel-1': cancel1,
    'delete-2': delete2,
    'cloud-upload': cloudUpload,
    'more-vertical-circle-1': verticalCircle,
    'plug': plug,
    'clock': clock,
};

export default function CustomIcon({
    name = 'dashboard-square',
    color = white,
    size = 50,
    fill,
    opacity = 1,
    strokeWidth = '1.5' //strokeWidth needs to be edited to none in all of the icon svg files for this to work
}) {
    const SelectedIcon = icons[name];
    if (!SelectedIcon) {
        console.warn(`Icon "${name}" not found.`);
        return null; // or you can return a default icon
    };
    // NOTE: does this need fill?
    return (
        <SelectedIcon
            width={size}
            height={size}
            style={{ color, opacity, strokeWidth: strokeWidth }}
        />
    );
};