// Layout.js
import React, { useEffect, useState, createContext, useContext } from "react";
import Header from './components/header';
import { Outlet } from 'react-router-dom';
import { fetchOrgID, fetchIsOverviewAccount, fetchOrganizationIDs, fetchSpecificTeamOrganizationIDs, } from "./functions/organization";
import { fetchProductTypes } from "./functions/outletList";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const Layout = () => {
  const [orgID, setOrgID] = useState(
    localStorage.getItem("orgID") || ""
  );
  const [orgName, setOrgName] = useState(
    localStorage.getItem("orgName") || ""
  );
  const [allOrgs, setAllOrgs] = useState();
  const [isOverviewAccount, setIsOverviewAccount] = useState(
    localStorage.getItem("isOverviewAccount") || false
  );
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID") || ""
  );
  const [timezone, setTimezone] = useState(
    localStorage.getItem("timezone") || "UTC"
  );
  const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);
  const [child_orgs, setChild_Orgs] = useState([]);
  const [allProdTypes, setAllProdTypes] = useState(localStorage.getItem("productTypes") || []);

  const [layoutProps, setLayoutProps] = useState({
    orgID: localStorage.getItem("orgID") || "",
    orgName: localStorage.getItem("orgName") || "",
    timezone: localStorage.getItem("timezone") || "UTC",
    setTimezone: setTimezone,
    rate: localStorage.getItem("rate") || 0.16,
    setRate: setRate,
    selectedOrgID: localStorage.getItem("selectedOrgID") || "",
    setSelectedOrgID: setSelectedOrgID,
    allOrgs: localStorage.getItem("allOrgs") || [],
    child_orgs: localStorage.getItem("child_orgs") || [],
    setChild_Orgs: setChild_Orgs,
    isOverviewAccount: localStorage.getItem("isOverviewAccount") || false,
    allProdTypes: localStorage.getItem("productTypes") || [],
  });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // // Function to fetch data from Firestore
    const fetchAndSetOrgID = async () => {
      if (orgID == "" || orgID == 'LZqI3R6MInUuwtgtROPK') {
        const {
          orgID: fetchedOrgID,
          timezone: fetchedTimezone,
          rate: fetchedRate,
          orgName: fetchedOrgName,
          child_orgs: fetchedChildOrgs,
        } = await fetchOrgID();
        let fetchedIsOverviewAccount = false;
        let fetchedAllOrgs = [];
        const fetchedProductTypes = await fetchProductTypes(setAllProdTypes); // Call fetchData function when component mounts
        if (fetchedOrgID) {
          setOrgID(fetchedOrgID);
          localStorage.setItem("orgID", fetchedOrgID);
          fetchedIsOverviewAccount = await fetchIsOverviewAccount(fetchedOrgID, setIsOverviewAccount)
          if (fetchedOrgID === "LZqI3R6MInUuwtgtROPK") {
            fetchedAllOrgs = await fetchOrganizationIDs(setAllOrgs);
          }else if(fetchedOrgID !== "") {
            fetchedAllOrgs = await fetchSpecificTeamOrganizationIDs(fetchedOrgID, setAllOrgs);
          };
        };
        if (fetchedTimezone) {
          setTimezone(fetchedTimezone);
          localStorage.setItem("timezone", fetchedTimezone);
        };
        if (fetchedRate) {
          setRate(fetchedRate);
          localStorage.setItem("rate", fetchedRate);
        };
        if (fetchedOrgName) {
          setOrgName(fetchedOrgName);
          localStorage.setItem("orgName", setOrgName);
        };
        if (fetchedChildOrgs) {
            setChild_Orgs(fetchedChildOrgs);
            localStorage.setItem('child_orgs', fetchedChildOrgs);
        };
        setLayoutProps(prevState => ({
          ...prevState,        // Spread the previous state
          orgID: fetchedOrgID,
          timezone: fetchedTimezone,
          rate: fetchedRate,
          orgName: fetchedOrgName,
          child_orgs: fetchedChildOrgs,
          isOverviewAccount: fetchedIsOverviewAccount,
          allOrgs: fetchedAllOrgs,
          allProdTypes: fetchedProductTypes,
        }));
      };
    };
    fetchAndSetOrgID();
  }, [orgID]);

  useEffect(() => {
    console.log('layout props',layoutProps);
  }, [layoutProps]);

  useEffect(() => {
    setLayoutProps(prevState => ({
      ...prevState,        // Spread the previous state
      selectedOrgID:selectedOrgID,
      timezone: timezone,
      rate: rate,
      child_orgs: child_orgs,
    }));
  }, [selectedOrgID]);

  return (
    <div>
      <Header
        orgID={orgID}
        selectedOrgID={selectedOrgID}
        setSelectedOrgID={setSelectedOrgID}
        setTimezone={setTimezone}
        setRate={setRate}
        width={windowDimensions.width}
        isOverviewAccount={isOverviewAccount}
      />
      <main style={{
        marginTop: '86px',
      }}>
        <Outlet context={layoutProps}/>
      </main>
    </div>
  );
};

export default Layout;
