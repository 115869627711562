import React from "react";
import { Box, Typography } from "@mui/material";
import { neutral300, neutral500, white } from "../../lib/rebrandColors/colors";
import CustomEventCard from "../eventCard";

export default function CalendarCard({
  dayNumber,
  state = "normal",
  currentDayNumber,
  events = [],
  weekend = false,
  setIsEventEditModalOpen,
  setSelectedEvent,
}) {
  const getDayNumberColor = () => {
    if (dayNumber === currentDayNumber) {
      return white;
    } else if (state === "disabled") {
      return neutral300;
    } else {
      return neutral500;
    };
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "150px",
        height: "120px",
        border: `1px solid ${neutral300}`,
        backgroundColor: weekend ? "#F1F1F1" : white,
        transition: "all 0.2s ease-in-out",
      }}
    >
      {/* Day Number in the Top Right */}
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: dayNumber === currentDayNumber ? "24px" : "auto",
          height: dayNumber === currentDayNumber ? "24px" : "auto",
          borderRadius: dayNumber === currentDayNumber ? "50%" : "none",
          backgroundColor:
            dayNumber === currentDayNumber ? "#E50914" : "transparent",
          color: getDayNumberColor(),
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "IBM Plex Mono",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "0.5px",
            color: getDayNumberColor(),
          }}
        >
          {dayNumber}
        </Typography>
      </Box>

      {/* Render Events */}
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "1px",
          alignItems: "center",
          overflowY:"auto",
          scrollbarWidth:"none" // This is to scroll in case there are more events but hide the scrollbar
        }}
      >
        {events.map((event, index) => (
          <CustomEventCard
            key={index}
            eventTitle={event.title}
            eventSubtitle={event.subtitle}
            iconName={event.iconName}
            event={events[index]}
            setIsEventEditModalOpen={setIsEventEditModalOpen}
            setSelectedEvent={setSelectedEvent}
          />
        ))}
      </Box>
    </Box>
  );
}
