import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../firebase";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

const OTPLoginScreen = () => {
  const [showOtp, setShowOtp] = useState(false);
  const toggleOtpVisibility = () => {
    setShowOtp(showOtp ? false : true);
  };
  const [verification, setverification] = useState("");
  const [otp, setOtp] = useState("");
  const [user, waiting] = useAuthState(auth);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (waiting) return;
  //   if (!user) navigate("/login");
  // }, [navigate, user, waiting]);

  useEffect(() => {
    //check if the user has completed the first login step
    const verfifer = JSON.parse(window.localStorage.getItem("approvedSignIn"));
    if (verfifer === true) {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
      sendOtp();
    } else {
      navigate("/");
    }
  }, []);

  const sendOtp = async () => {
    if (!window.resolver || !window.resolver.hints) {
      console.error("Resolver is not set or missing hints. Cannot send OTP.");
      return;
    }
    try {
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );
      window.recaptchaVerifier = recaptchaVerifier;
      recaptchaVerifier.render();
      const phoneOpts = {
        multiFactorHint: window.resolver.hints[0],
        session: window.resolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      await phoneAuthProvider
        .verifyPhoneNumber(phoneOpts, recaptchaVerifier)
        .then((verificationId) => {
          setverification(verificationId);
        });
      toast.success("Code sent successfully");
    } catch (err) {
      console.log(err);
      if (err.code === "auth/network-request-failed") {
        console.error(err);
      } else {
        console.error("Error sending OTP:", err);
      }
    }
  };

  //verify otp
  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const cred = PhoneAuthProvider.credential(verification, otp);

      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const credential = await window.resolver
        .resolveSignIn(multiFactorAssertion)
        .then((enrollment) => {
          auth.onAuthStateChanged(async (users) => {
            if (users) {
              window.localStorage.removeItem("approvedSignIn");
              navigate("/");
            }
          });
        });
    } catch (err) {
      toast.error("Invalid OTP");
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="ReVert Logo"
          style={{ width: "200px", marginBottom: "2rem" }}
        />
        <Typography variant="h6" sx={{ mb: 0, fontWeight: "bold" }}>
          A code has been sent to your phone
        </Typography>
        <form onSubmit={verifyOtp} style={{ width: "100%" }}>
          <TextField
            type={showOtp ? "text" : "password"}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="OTP"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleOtpVisibility} edge="end">
                    {showOtp ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="h8" sx={{ mb: 3, fontWeight: "bold" }}>
            Please enter the code here
          </Typography>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: "green",
              color: "white",
              "&:hover": { bgcolor: "darkgreen" },
            }}
          >
            Verify
          </Button>
        </form>
      </Box>
      <div
        id="recaptcha-container"
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      ></div>
      <ToastContainer />
    </Box>
  );
};

export default OTPLoginScreen;
