import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../firebase";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from "firebase/auth";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { addUserToOrganization, registerNewUser } from "../../functions/users";
import { useAuthState } from "react-firebase-hooks/auth";

const OTPScreen = () => {
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [verification, setVerification] = useState("");
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [user, waiting, error] = useAuthState(auth);

  useEffect(() => {
    if (waiting) return;
    if (!user) navigate("/login");
  }, [navigate, user, waiting]);

  useEffect(() => {
    if (userData === null) {
      navigate("/register");
    } else {
      sendOtp(userData);
    }
  }, [navigate]);

  const sendOtp = async () => {
    try {
      const decryptedPhone = userData.phone;
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );

      try {
        auth.onAuthStateChanged(async (user) => {
          if (user) {
            const multiFactorSession = await multiFactor(user).getSession();
            const phoneInfoOptions = {
              phoneNumber: decryptedPhone,
              session: multiFactorSession,
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              recaptchaVerifier
            );
            setVerification(verificationId);
            toast.success("Code sent successfully");
          }
        });
      } catch (error) {
        console.error("Error sending OTP:", error);
        toast.error("Failed to send OTP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      if (user) {
        const cred = PhoneAuthProvider.credential(verification, otp);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        await multiFactor(user).enroll(multiFactorAssertion, "phone number");
        await registerNewUser(user, userData.orgID, "pending", userData.phone);
        await addUserToOrganization(user.uid, userData.orgID);
        localStorage.removeItem("user");
        navigate("/");
      }
    } catch (err) {
      console.error("OTP verification error:", err);
      toast.error("Invalid OTP");
    }
  };

  const toggleOtpVisibility = () => {
    setShowOtp(!showOtp);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="ReVert Logo"
          style={{ width: "200px", marginBottom: "2rem" }}
        />
        <Typography variant="h6" sx={{ mb: 0, fontWeight: "bold" }}>
          A code has been sent to your phone
        </Typography>
        <form onSubmit={verifyOtp} style={{ width: "100%" }}>
          <TextField
            type={showOtp ? "text" : "password"}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="OTP"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleOtpVisibility} edge="end">
                    {showOtp ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="h8" sx={{ mb: 3, fontWeight: "bold" }}>
            Please enter the code here
          </Typography>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: "green",
              color: "white",
              "&:hover": { bgcolor: "darkgreen" },
            }}
          >
            Verify
          </Button>
        </form>
      </Box>
      <div
        id="recaptcha-container"
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      ></div>
      <ToastContainer />
    </Box>
  );
};

export default OTPScreen;
