import React, { useState, useEffect } from "react";
import { LineChart, } from '@mui/x-charts/LineChart';
import { useLocation, useOutletContext } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
    aggregateByHourWattHour,
    aggregateByMonthWattHour,
    calculateTotalAverage,
    filterAverageUsage
} from "../../functions/wattHours";
import CustomIcon from "../icon";
import { black } from "../../lib/rebrandColors/colors";

export default function DailyUsageChart({
    savings,
    wattHours,
    averageUsage,
    averageLoading,
    wattsLoading,
    savingsLoading,
}) {
    const location = useLocation();
    const {
        orgID,
        orgName,
        isOverviewAccount,
        selectedOrgID,
        // child_orgs,
        timezone,
        allProdTypes,
        allOrgs,
        setTimezone,
        setRate,
        setSelectedOrgID,
        // setChild_Orgs
    } = useOutletContext();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateString, setDateString] = useState(selectedDate.toISOString().split('T')[0]);
    const [dayString, setDayString] = useState(selectedDate.toLocaleString('en-us', { weekday: 'short' }));

    useEffect(() => {
        setDateString(selectedDate.toISOString().split('T')[0]);
        setDayString(selectedDate.toLocaleString('en-us', { weekday: 'short' }));
    }, [selectedDate]);

    if (averageLoading === false && wattsLoading === false && savingsLoading === false) {
        const wattHoursObject = wattHours.reduce((acc, item) => {
            const { date, outletID, hourlyWattHour } = item;
            if (!acc[date]) {
                acc[date] = [];
            };
            acc[date].push({
                outletID,
                hourlyWattHour,
            });
            return acc;
        }, {});
        const filteredAverageUsage = filterAverageUsage(averageUsage, wattHours);
        const dailyAverage = calculateTotalAverage(filteredAverageUsage);

        //NOTE: aggregators pre convert to kWhs
        const multiWeekData = aggregateByMonthWattHour(wattHoursObject);
        // console.log('day', dateString, dayString);
        // ensure that this passes as '2024-11-20'
        const dailyWattHoursData = aggregateByHourWattHour(wattHoursObject, dateString);
        const dailyWattHoursDataWithAverage = dailyWattHoursData.map((entry, index) => ({
            ...entry,
            averageWattHour: dailyAverage[dayString][index]
        }));
        
        return (
            <div style={{
                minWidth: '500px',
                height: '400px',
                margin: '.5%',
            }}>
                {location.pathname === '/' ?
                    <div div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                        marginTop: '40px',
                    }}>
                        <CustomIcon
                            name="connect"
                            color={black}
                            size={30}
                        />
                        <div
                            className="bodyLarge"
                            style={{
                                marginLeft: '8px',
                            }}
                        >
                            Daily Usage
                        </div>
                    </div>
                    :
                    <div style={{height: '60px'}}></div>
                }
                <LineChart
                    dataset={dailyWattHoursDataWithAverage}
                    sx={{
                        '& .MuiLineElement-series-averageWattHour': {
                            stroke: '#E56208',              // Line color
                            strokeDasharray: '5 5',       // Dashed line pattern
                            strokeWidth: 3,                // Line width
                        },
                        '& .MuiAreaElement-series-averageWattHour': {
                            fill: "url('#averageGradient')",
                        },
                        '& .MuiAreaElement-series-totalWattHour': {
                            fill: "url('#usageGradient')",
                        },
                    }}
                    series={[
                        {
                            dataKey: "averageWattHour",
                            id: 'averageWattHour',
                            label: 'Average Usage',
                            color: "#E56208",
                            showMark: true,
                            area: true,
                            curve: 'catmullRom',
                        },
                        {
                            dataKey: "totalWattHour",
                            id: "totalWattHour",
                            label: 'With Revert',
                            color: '#2789FF',
                            showMark: true,
                            area: true,
                            curve: 'catmullRom',
                        },
                    ]}
                    xAxis={[
                        {
                            dataKey: "hour",
                            valueFormatter: (value) => {
                                const hour = value % 12 || 12;
                                const ampm = value < 12 ? 'AM' : 'PM';
                                return `${hour}:00 ${ampm}`;
                            },
                            min: 0,
                            max: 23,
                        },
                    ]}
                    yAxis={[
                        {
                            label: "kWhs",
                            offset: 15,
                            fontSize: 14,
                            color: black,
                        },
                    ]}
                >
                    <defs>
                        <linearGradient id="averageGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#E56208" stopOpacity={.5} />
                            <stop offset="50%" stopColor="#E56208" stopOpacity={.25} />
                            <stop offset="100%" stopColor="#E56208" stopOpacity={.05} />
                        </linearGradient>
                        <linearGradient id="usageGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#2789FF" stopOpacity={.95} />
                            <stop offset="50%" stopColor="#2789FF" stopOpacity={.55} />
                            <stop offset="100%" stopColor="#2789FF" stopOpacity={.3} />
                        </linearGradient>
                    </defs>
                </LineChart>
            </div >
        );
    } else {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '50px'
            }}>
                <CircularProgress />
            </div>
        );
    };
};