import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

// Fetch events for a specific organization
export const fetchNotifications = async (orgIDs, setNotifications, setNotificationsLoading) => {
  console.log(orgIDs)
  const notificationsQuery = query(
    collection(db, "notifications"),
    where("orgID", "in", orgIDs)
  );
  const notificationsSnapshot = await getDocs(notificationsQuery);
  const notifications = notificationsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  setNotifications(notifications);
  setNotificationsLoading(false);
};

// Mark notification as read or unread
export const updateNotifications = async (notificationID, status) => {
  try {
    const notificationRef = doc(db, "notifications", notificationID);
    if (notificationRef) {
      await updateDoc(notificationRef, {
        status: status,
      });
      console.log("Notification successfully updated!");
    }
  } catch (error) {
    console.error("Error updating event: ", error);
  }
};

