import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

// Fetch events for a specific organization
export const fetchOrgEvents = async (organizationID, setEvents) => {
  const eventsQuery = query(
    collection(db, "events"),
    where("organizationID", "==", organizationID)
  );
  const eventsSnapshot = await getDocs(eventsQuery);
  const events = eventsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  // console.log("Events: ", events);
  setEvents(events);
};

// Fetch events for child organizations
export const fetchChildOrgEvents = async (organizationID, setEvents, child_orgs) => {
  const eventsQuery = query(
    collection(db, "events"),
    where("organizationID", "in", child_orgs)
  );
  const eventsSnapshot = await getDocs(eventsQuery);
  const events = eventsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  // console.log("Events: ", events);
  setEvents(events);
};

// Delete a specific event
export const deleteOrgEvent = async (eventID) => {
  try {
    const eventRef = doc(db, "events", eventID);
    if (eventRef) {
      await deleteDoc(eventRef);
      console.log("Event successfully deleted!");
    }
  } catch (error) {
    console.error("Error deleting event: ", error);
  }
};

