import db from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

// NOTE: Return to query schedules
export const fetchOrgSchedules = async (orgIDs, setSchedules, setSchedulesLoading) => {
  const schedulesQuery = query(
    collection(db, "schedules"),
    where("orgID", "in", orgIDs)
  );
  const schedulesSnapshot = await getDocs(schedulesQuery);
  const schedules = schedulesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
    // console.log("Schedules: ", schedules);
  setSchedules(schedules);
  setSchedulesLoading(false);
};

export const deleteOrgSchedule = async (scheduleID) => {
  try {
    const scheduleRef = doc(db, "schedules", scheduleID);
    if (scheduleRef) {
      await deleteDoc(scheduleRef);
      console.log("Schedule successfully deleted!");
    }
  } catch (error) {
    console.error("Error deleting schedule: ", error);
  }
};

export const updateStatus = async (scheduleID, status) => {
  try {
    const scheduleRef = doc(db, "schedules", scheduleID);
    await updateDoc(scheduleRef, {
      status: status,
    });

    console.log("Schedule status successfully updated!");
  } catch (error) {
    console.error("Error updating schedule status: ", error);
  }
};
