import React, { useEffect, useState } from "react";
import AccountInfo from "../../components/accountInfo";
import UsersDataGridTable from "../../components/users-data-grid";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { fetchUsersFromOrganization } from "../../functions/users";
import { logout } from "../../firebase";
import { sendPasswordResetEmail } from "@firebase/auth";
import EnableMFAModal from "../../components/enableMFA";
import TextButton from "../../components/textButton";
import CustomLogo from "../../components/logo";
import OrganizationPicker from "../../components/orgPicker";
import { useOutletContext } from "react-router-dom";
import { fetchUserRole } from "../../functions/users";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const AccountDetails = () => {
  const [user, waiting] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { orgID, orgName, isOverviewAccount, selectedOrgID, child_orgs, timezone, allOrgs, setTimezone, setRate, setSelectedOrgID, setChild_Orgs } = useOutletContext();
  const [mfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [roleLoading, setRoleLoading] = useState(true);
  const [role, setRole] = useState('');

  const handleOpenMfaModal = () => {
    setMfaModalOpen(true);
  };

  const handleCloseMfaModal = () => {
    setMfaModalOpen(false);
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // State for alert
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "", // Can be "info", "success", "error", etc.
  });

  // Fetch user role
  useEffect(() => {
    if (waiting) return;
    const getRole = async () => {
      const userRole = await fetchUserRole(user.uid);
      setRoleLoading(false);
      setRole(userRole);
      if (userRole === "pending") {
        navigate("/pending-role");
      };
    };
    if (user) {
      getRole();
    } else {
      navigate("/login");
    };
  }, [user, waiting]);

  const getUsers = async () => {
    setLoading(true);
    let usersData;
    if (selectedOrgID !== "") {
      usersData = await fetchUsersFromOrganization(selectedOrgID);
    } else {
      usersData = await fetchUsersFromOrganization(orgID);
    }
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    if (orgID) {
      getUsers();
    }
  }, [orgID, selectedOrgID]);

  const sendResetEmail = async () => {
    if (user) {
      try {
        await sendPasswordResetEmail(auth, user.email);
        setAlert({
          show: true,
          message: "Please check your inbox to reset your password.",
          severity: "info",
        });
      } catch (error) {
        setAlert({
          show: true,
          message: "Error sending password reset email. Please try again.",
          severity: "error",
        });
      }
    } else {
      setAlert({
        show: true,
        message: "User not found. Please try again.",
        severity: "error",
      });
    }
  };

  const handleLogout = () => {
    if (user) {
      logout();
      navigate("/login");
    };
  };

  return (
    <div style={{
      display: "flex",
      backgroundColor: '#1F1F1F',
      height: 'auto',
      minHeight: '100vh',
      minWidth: '1300px',
      justifyContent: 'center',
      marginTop: '86px'
    }}>
      <div
        style={{
          marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
          display: "flex",
          flexDirection: "column",
          //marginLeft: '153px',
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
          <div className="headlineLarge"
            style={{
              width: "350px",
              height: "auto",
              display: "block",
              alignSelf: 'start',
              // marginLeft: '18%',
              marginTop: '10px',
              color: '#FFFFFF',
              //marginRight:'100px',
            }}
          >
            Account management
          </div>
          {(isOverviewAccount || orgID == 'LZqI3R6MInUuwtgtROPK') &&
            <OrganizationPicker
              allOrgs={allOrgs}
              orgID={orgID}
              selectedOrgID={selectedOrgID}
              setSelectedOrgID={setSelectedOrgID}
              setTimezone={setTimezone}
              setRate={setRate}
              label={true}
            />
          }
        </div>
        <div
          style={{
            marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
            display: "flex",
            flexDirection: "row",
            marginBottom: '30px'
          }}
        >
          <div
            style={{
              width: "560px",
              borderRadius: '24px',
              minHeight: '824px',
              backgroundColor: '#FAFAFA',
              maxHeight: '824px',
              overflow: 'scroll',
              marginRight: '16px',
            }}
          >
            <div style={{
              marginTop: '40px',
              marginLeft: '40px',
            }}>
              <div
                className='titleLarge'
                style={{ marginBottom: '24px' }}
              >
                Details
              </div>
              <AccountInfo
                orgID={selectedOrgID !== "" ? selectedOrgID : orgID}
                role={role}
              />
              <div style={{
                marginTop: '32px',
                width: '480px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <TextButton
                  label={'Sign Out'}
                  underlined={true}
                  onPress={handleLogout}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "560px",
              borderRadius: '24px',
              minHeight: '824px',
              maxHeight: '824px',
              backgroundColor: '#FAFAFA',
              overflow: 'hidden'
            }}
          >
            <div style={{
              marginTop: '40px',
              marginLeft: '40px',
            }}>
              <div
                className='titleLarge'
                style={{ marginBottom: '24px' }}
              >
                Organization Members
              </div>
              <UsersDataGridTable
                user={user}
                data={users}
                loading={loading}
                getUsers={getUsers}
                orgID={selectedOrgID !== "" ? selectedOrgID : orgID}
                role={role}
              />
            </div>
          </div>
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}>
          <CustomLogo name="horizontal-white" />
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
