import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { sendPasswordResetEmail, } from "@firebase/auth";
import TextField from "../../components/textField";
import CustomButton from "../../components/customButton";
import CustomLogo from "../../components/logo";
import { white } from "../../lib/rebrandColors/colors";
import '../../App.css'; // Import your fonts

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
        width,
        height,
    };
};

export default function PasswordReset() {
    const [email, setEmail] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        message: "",
        severity: "",
    });

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        };


        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const sendResetEmail = async () => {
        if (email !== "") {
            try {
                await sendPasswordResetEmail(auth, email);
                setAlert({
                    show: true,
                    message: "Please check your inbox to reset your password",
                    severity: "info",
                });
            } catch (error) {
                if (error.message === "Firebase: Error (auth/invalid-email).") {
                    setAlert({
                        show: true,
                        message: "Please verify that you entered the correct email",
                        severity: "error",
                    });
                } else {
                    setAlert({
                        show: true,
                        message: "Please verify that you entered the correct email",
                        severity: "error",
                    });
                };
            };
        } else {
            setAlert({
                show: true,
                message: "Please enter your email",
                severity: "error",
            });
        };
    };

    const handleEmailChange = (value) => {
        // const value = e.target.value;

        // Basic email regex pattern:
        // - Starts with one or more characters (letters, numbers, dots, underscores, or hyphens)
        // - Must contain the @ symbol
        // - Domain name part with one or more characters
        // - Dot (.) followed by a valid top-level domain (2-6 characters)
        const isValid = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/.test(value);

        if (isValid) {
            setEmail(value);
            setEmailError(false);
            setIsDisabled(false);
        } else {
            setEmailError(true);
            setIsDisabled(true);
        };
    };

    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: '#1F1F1F',
                height: 'auto',
                minHeight: '100vh',
                minWidth: '1000px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div
                    style={{
                        display: "flex",
                        width: '50%',
                        minWidth: '550px',
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            marginTop: '30px',//windowDimensions.height < 415 ? '30px' : '8vh',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: 'center',
                        }}
                    >
                        <div style={{
                            width: "160px",
                            height: "auto",
                            display: "block",
                            alignSelf: 'start',
                            marginTop: '10px'
                        }}>
                            <CustomLogo
                                name="horizontal-negative"
                            />
                        </div>
                        <div
                            className="displayLarge"
                            style={{
                                color: white,
                                fontWeight: 700,
                                marginTop: '50px',
                                fontSize: "48px",
                                textAlign: "left",
                                alignSelf: 'start',
                            }}
                        >
                            Forgot Password?
                        </div>
                        <div
                            className="bodyLarge"
                            style={{
                                color: '#B7B7B7',
                                fontSize: "16px",
                                textAlign: "left",
                                alignSelf: 'start',
                            }}
                        >
                            Enter your email and we'll send you a link to reset it.
                        </div>
                        <div style={{
                            marginTop: '40px',
                            alignSelf: 'start',
                        }}>
                            <TextField
                                placeholder={"Email"}
                                size={"medium"}
                                onChange={handleEmailChange}
                                descriptionText={alert.show ? alert.message : ''}
                                textError={alert.show ? true : false}
                            />
                        </div>
                        <div style={{
                            alignSelf: 'start',
                            paddingTop: '20px',
                            marginBottom: '1%',
                        }}>
                            <CustomButton
                                size={"large"}
                                custFontSize={16}
                                filled={true}
                                disabled={isDisabled}
                                label='Send reset link'
                                iconName={'arrow-right'}
                                iconPosition="right"
                                onClick={sendResetEmail}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        alignSelf: 'center',
                        display: "flex",
                        height: '95vh',
                        minHeight: '400px',
                        width: '47.5%',
                        minWidth: '400px',
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        backgroundColor: 'white',
                        borderRadius: '20px',
                    }}
                >
                </div>
            </div>
        </>
    );
};