import React, { useState } from "react";
import TextButton from "../textButton";
import { neutral300, primary500, white } from "../../lib/rebrandColors/colors";

export default function TabBar({
    labelArr, //array of strings to be displayed as text buttons, NOTE: THESE ARE ZERO INDEXED
    iconArr, //array of icon names if being used in text buttons, NOTE: THESE ARE ZERO INDEXED
    iconPos, //position for icons if being used
    fill, //word color
    onPressArr, //array of functions to be aligned with indecies from label arr, NOTE: THESE ARE ZERO INDEXED
    width,
    size = 'large', //large or small
    activeIndex, //setting this would need to be included in the onclick functions I think
    fontSize, //if custom font size needed
}) {


    return (
        <div style={{
            display: 'flex',
            gap: size === 'small' ? '8px' : '28px',
            height: size === 'small' ? '31px' : '54px',
            // alignItems: 'center',
            paddingTop: size === 'small' ? '0px' : '32px'
        }}>
            {labelArr ?
                labelArr.map((label, i) => {
                    return (
                        <div
                            key={i}
                            style={{
                                borderBottomColor: activeIndex === i ? primary500 : size === 'small' ? neutral300 :
                                    'transparent',
                                borderBottomWidth: size === 'small' ? '1px' : '4px',
                                borderBottomStyle: 'solid',
                            }}
                        >
                            <TextButton
                                label={label}
                                icon={iconArr ? iconArr[i] : null}
                                iconPosition={iconPos}
                                fill={activeIndex === i ? size === 'small' ? primary500 : white : neutral300}
                                onPress={onPressArr[i]}
                                fontSize={fontSize}
                            />
                        </div>
                    )
                })
                : null
            }
        </div>
    );
};