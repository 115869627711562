import React, { useState, useEffect } from "react";
import { white } from "../../lib/rebrandColors/colors";
import "../../App.css"; // Import your fonts
import CalendarContainer from "../../components/calendarContainer";
import {
    fetchOrgSchedules,
} from "../../functions/scheduleTable";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { fetchUserRole } from "../../functions/users";
import { useNavigate } from "react-router-dom";
import {
    fetchOrgID,
    fetchIsOverviewAccount,
    fetchOrganizationInfoFromID,
} from "../../functions/organization";
import { fetchOrgEvents, fetchChildOrgEvents } from "../../functions/events";
import Dropdown from "../../components/dropDown";
import { fetchOutlets } from "../../functions/outletList";
import OrganizationPicker from "../../components/orgPicker";
import CustomLogo from "../../components/logo";
import { useOutletContext } from "react-router-dom";
import CalendarContainerRecommended from "../../components/calendarContainerRecommended";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

export default function Schedule() {
    const navigate = useNavigate();
    // from layout
    const {
        orgID,
        orgName,
        isOverviewAccount,
        selectedOrgID,
        // child_orgs,
        timezone,
        allProdTypes,
        allOrgs,
        setTimezone,
        setRate,
        setSelectedOrgID,
        // setChild_Orgs
    } = useOutletContext();
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const [update, setUpdate] = useState(false);
    const [user, waiting] = useAuthState(auth);
    const [roleLoading, setRoleLoading] = useState(true);
    const [child_orgs, setChild_Orgs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [outlets, setOutlets] = useState(JSON.parse(localStorage.getItem("outlets")));
    const [outletsLoading, setOutletsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [orgData, setOrgData] = useState([]);
    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [subOrgDropdownValue, setSubOrgDropdownValue] = useState('All');
    const [subOrgDropdownOpen, setSubOrgDropdownOpen] = useState(false);
    const subOrgDropdownClick = (item) => {
        setSubOrgDropdownValue(item);
    };
    const [subOrgDropdownArr, setSubOrgDropdownArr] = useState(['All'])
    const [prodTypeDropdownValue, setProdTypeDropdownValue] = useState('All');
    const [prodTypeDropdownOpen, setProdTypeDropdownOpen] = useState(false);
    const prodTypeDropdownClick = (item) => {
        setProdTypeDropdownValue(item);
    };
    const [prodTypeDropdownArr, setProdTypeDropdownArr] = useState(['All'])
    const [prodTypes, setProdTypes] = useState([]);
    const [filteredSchedules, setFilteredSchedules] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [role, setRole] = useState('');

    // const [orgID, setOrgID] = useState("");
    // const [orgName, setOrgName] = useState("");
    // const [isOverviewAccount, setIsOverviewAccount] = useState(
    //     localStorage.getItem("isOverviewAccount") === "true" || false
    // );
    // const [rate, setRate] = useState(localStorage.getItem("rate") || 0.16);
    // const [selectedOrgID, setSelectedOrgID] = useState(
    //     localStorage.getItem("selectedOrgID") || ""
    // );
    // Events state
    // Schedules state
    // const [timezone, setTimezone] = useState(
    //     localStorage.getItem("timezone") || "UTC"
    // );

    // Fetch events
    useEffect(() => {
        const fetchEvents = async () => {
            if (selectedOrgID !== "" && child_orgs?.length === 0) {
                await fetchOrgEvents(selectedOrgID, setEvents);
            } else if (child_orgs.length !== 0) {
                await fetchChildOrgEvents(orgID, setEvents, child_orgs);
            } else {
                await fetchOrgEvents(orgID, setEvents);
            };
        };

        if (orgID) {
            fetchEvents();
        };
    }, [orgID, selectedOrgID, child_orgs, refresh]);

    // Fetch user role
    useEffect(() => {
        if (waiting) return;
        const getRole = async () => {
            const userRole = await fetchUserRole(user.uid);
            setRoleLoading(false);
            setRole(userRole);
            if (userRole === "pending") {
                navigate("/pending-role");
            };
        };
        if (user) {
            getRole();
        } else {
            navigate("/login");
        };
    }, [user, waiting]);

    // fetches schedules and org info
    useEffect(() => {
        const fetchDashboardInfo = async () => {
            //get the (selected) org document information from the firebase
            let orgData = []
            let orgNameArray = []
            if (selectedOrgID !== "") {

                orgData = await fetchOrganizationInfoFromID(selectedOrgID);
                setOrgData([orgData])
            } else if (orgID !== "") {
                orgData = await fetchOrganizationInfoFromID(orgID);
                setOrgData([orgData]);
            };

            //if the (selected) org has child orgs make sure to update the child orgs in storage
            if (orgData.child_orgs) {
                setChild_Orgs(orgData.child_orgs);
                localStorage.setItem('child_orgs', orgData.child_orgs);
                if (orgData.child_orgs.length > 0) {
                    const orgDataPromises = orgData.child_orgs.map(org => fetchOrganizationInfoFromID(org));
                    const orgDataArray = await Promise.all(orgDataPromises);
                    setOrgData(orgDataArray)
                };
            } else {
                setChild_Orgs([]);
                localStorage.setItem('child_orgs', []);
            };

            //get schedules and wattHours
            if (orgData.child_orgs) {
                if (orgData.child_orgs.length > 0) {
                    fetchOrgSchedules(orgData.child_orgs, setSchedules, setScheduleLoading);
                    // fetchWattHours(orgData.child_orgs, setWattHours, setWattsLoading);
                };
            } else if (selectedOrgID !== "") {
                fetchOrgSchedules([selectedOrgID], setSchedules, setScheduleLoading);
                // fetchWattHours([selectedOrgID], setWattHours, setWattsLoading);
            } else {
                fetchOrgSchedules([orgID], setSchedules, setScheduleLoading);
                // fetchWattHours([orgID], setWattHours, setWattsLoading);
            };
        };
        fetchDashboardInfo();
    }, [selectedOrgID, timezone, selectedDay, orgID, refresh]);

    // Resize handler
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    //sets schedules display variable
    useEffect(() => {
        setFilteredSchedules(schedules);
        setFilteredEvents(events);
    }, [schedules, selectedDay, refresh, events]);

    // sets subOrg dropdown
    useEffect(() => {
        if (orgData.length > 0) {
            const extractedValues = orgData.map(obj => obj.name);
            const addObjectsToState = () => {
                setSubOrgDropdownArr(prevState => ['All', ...extractedValues]);
            };
            addObjectsToState();
        } else {
            setSubOrgDropdownArr(prevState => ['All', orgData.name]);
        };
    }, [orgData]);

    // sets org filter for schedules
    useEffect(() => {
        if (orgData.length > 0) {
            const filteredOrg = orgData.find((org) => org.name === subOrgDropdownValue);
            if (filteredOrg) {
                const newFilteredSchedules = schedules.filter(
                    (schedule) => {
                        // schedule.orgID === filteredOrg.uid
                        const orgMatches = schedule.orgID === filteredOrg.uid;

                        // If prodTypeDropdownValue is "All", skip the product type filter; otherwise, apply it
                        const productTypeMatches = prodTypeDropdownValue === 'All' ||
                            schedule.outletsSelected.some(outletId => {
                                const matchingOutlet = outlets.find(
                                    outlet => outlet.id === outletId && outlet.productType === prodTypeDropdownValue
                                );
                                return Boolean(matchingOutlet);
                            });

                        // Only include schedules that match both the orgID and, if specified, the product type
                        return orgMatches && productTypeMatches;
                    }
                );
                setFilteredSchedules(newFilteredSchedules);
                const newFilteredEvents = events.filter((event) => {
                    const orgMatches = event.organizationID === filteredOrg.uid;
                    return orgMatches
                });
                setFilteredEvents(newFilteredEvents);
                // console.log('org filter', newFilteredSchedules, filteredOrg.uid);
            } else if (prodTypeDropdownValue !== 'All') {
                const newFilteredSchedules = schedules.filter(
                    (schedule) => {
                        // schedule.orgID === filteredOrg.uid
                        // If prodTypeDropdownValue is "All", skip the product type filter; otherwise, apply it
                        const productTypeMatches = prodTypeDropdownValue === 'All' ||
                            schedule.outletsSelected.some(outletId => {
                                const matchingOutlet = outlets.find(
                                    outlet => outlet.id === outletId && outlet.productType === prodTypeDropdownValue
                                );
                                return Boolean(matchingOutlet);
                            });

                        // Only include schedules that match both the orgID and, if specified, the product type
                        return productTypeMatches;
                    }
                );
                setFilteredSchedules(newFilteredSchedules);
            } else {
                setFilteredSchedules(schedules);
                setFilteredEvents(events);
                setProdTypeDropdownValue('All');
            };
        } else {
            // filter schedules by product type only if no child orgs
            let newFilteredSchedules;
            if (prodTypeDropdownValue !== 'All') {
                newFilteredSchedules = schedules.filter(schedule =>
                    schedule.outletsSelected.some(outletId => {
                        const matchingOutlet = outlets.find(
                            outlet => outlet.id === outletId && outlet.productType === prodTypeDropdownValue
                        );
                        return Boolean(matchingOutlet);
                    })
                );
            } else {
                newFilteredSchedules = schedules;
            };
            setFilteredSchedules(newFilteredSchedules);
        };
    }, [subOrgDropdownValue, orgData, prodTypeDropdownValue]);

    // gets all product types,
    useEffect(() => {
        let allProductTypes = [];
        schedules.forEach((schedule) => {
            let productTypes = [];

            schedule.outletsSelected.forEach(outlet => {
                if (outlets.find(obj => obj.id === outlet)) {
                    productTypes.push(outlets.find(obj => obj.id === outlet).productType);
                };
            });

            // Add unique product types from this schedule to allProductTypes
            allProductTypes = [...allProductTypes, ...new Set(productTypes)];
        });

        setProdTypes([...new Set(allProductTypes)]);
    }, [schedules, update]);

    // sets product type dropdown list
    useEffect(() => {
        if (prodTypes.length > 0) {
            const addTypesToState = () => {
                setProdTypeDropdownArr(prevState => ['All', ...prodTypes]);
            };
            addTypesToState();
        } else {
            setProdTypeDropdownArr(['All']);
        };
    }, [prodTypes,]);

    // queries the outlets every 2 min
    useEffect(() => {
        const interval = setInterval(() => {
            setUpdate(!update)
        }, 120000); // 120000ms = 120 seconds
        return () => {
            clearInterval(interval);
        };
    }, [update]);

    // gets outlet data
    useEffect(() => {
        const fetchTimezone = localStorage.getItem('timezone');
        // NOTE: changed from timezone to fetched timezone as it wasn't updating correctly
        setOutletsLoading(true);
        if (selectedOrgID !== "" && child_orgs.length == 0) {

            fetchOutlets([selectedOrgID], fetchTimezone, setOutlets, setOutletsLoading, setProdTypes);
        } else if (child_orgs.length > 0) {

            if (Array.isArray(child_orgs)) {
                fetchOutlets(child_orgs, fetchTimezone, setOutlets, setOutletsLoading, setProdTypes);
            } else {
                fetchOutlets(child_orgs.split(','), fetchTimezone, setOutlets, setOutletsLoading, setProdTypes);
            };
        } else {
            fetchOutlets([orgID], fetchTimezone, setOutlets, setOutletsLoading, setProdTypes);
        };
    }, [schedules, update, selectedOrgID, orgID, child_orgs, refresh]);


    return (
        <>
            <div style={{
                display: "flex",
                backgroundColor: '#1F1F1F',
                height: 'auto',
                minHeight: '100vh',
                minWidth: '1300px',
                justifyContent: 'center',
                marginTop: '86px'
            }}>
                <div
                    style={{
                        marginTop: '30px',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                        <div
                            className="headlineLarge"
                            style={{
                                width: "350px",
                                height: "auto",
                                display: "block",
                                alignSelf: 'start',
                                marginTop: '20px',
                                color: '#FFFFFF',
                            }}
                        >
                            Schedule
                        </div>
                        {orgID == 'LZqI3R6MInUuwtgtROPK' &&
                            <OrganizationPicker
                                allOrgs={allOrgs}
                                orgID={orgID}
                                selectedOrgID={selectedOrgID}
                                setSelectedOrgID={setSelectedOrgID}
                                setTimezone={setTimezone}
                                setRate={setRate}
                                label={true}
                            />
                        }
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div style={{ marginRight: '16px' }}>
                                {/* 'Buildings' i.e. suborgs, default will be to show all suborg schedules */}
                                {subOrgDropdownArr.length > 2 &&
                                    <Dropdown
                                        list={subOrgDropdownArr}
                                        value={subOrgDropdownValue}
                                        onSelect={subOrgDropdownClick}
                                        setIsDropdownOpen={setSubOrgDropdownOpen}
                                        isDropdownOpen={subOrgDropdownOpen}
                                        label={'Buildings'}
                                    />
                                }
                            </div>
                            {/* 'Appliance Type' start as all appliance types, should only display appliances that are listed in schedules */}
                            <Dropdown
                                list={prodTypeDropdownArr}
                                value={prodTypeDropdownValue}
                                onSelect={prodTypeDropdownClick}
                                setIsDropdownOpen={setProdTypeDropdownOpen}
                                isDropdownOpen={prodTypeDropdownOpen}
                                label={'Appliances'}
                            />
                        </div>
                    </div>

                    <div style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <CalendarContainer
                            events={filteredEvents}
                            schedules={filteredSchedules}
                            orgID={orgID}
                            selectedOrgID={selectedOrgID}
                            isOverviewAccount={isOverviewAccount}
                            timezone={timezone}
                            orgName={orgName}
                            child_orgs={child_orgs}
                            orgData={orgData}
                            loading={loading}
                            windowDimensions={windowDimensions}
                            outlets={outlets}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            subOrgDropdownValue={subOrgDropdownValue}
                            role={role}
                        />
                    </div>
                    <div style={{ marginTop: "30px", marginBottom: '20px' }}>
                        <CalendarContainerRecommended
                            events={filteredEvents}
                            schedules={filteredSchedules}
                            orgID={orgID}
                            selectedOrgID={selectedOrgID}
                            isOverviewAccount={isOverviewAccount}
                            timezone={timezone}
                            orgName={orgName}
                            child_orgs={child_orgs}
                            orgData={orgData}
                            loading={loading}
                            windowDimensions={windowDimensions}
                            outlets={outlets}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            subOrgDropdownValue={subOrgDropdownValue}
                            role={role}
                        />
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                    }}>
                        <CustomLogo name="horizontal-white" />
                    </div>
                </div>
            </div>
        </>
    );
};
