import React, { useEffect, useState } from "react";
import CustomIcon from "../icon";
import { primary500, white, primary600, primary580, primary550, neutral300, neutral350, neutral330 } from "../../lib/rebrandColors/colors";
import '../../App.css';

export default function CustomButton({
    disabled = false,
    filled = false,
    custFontSize,
    label = "label",
    size = "small",
    iconName,
    icon,
    iconPosition = 'right',
    onClick,
    colored = true,
}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const iconLineColor = disabled ?
        filled ?
            colored ? white : neutral300
            : primary500
        : filled ?
            colored ? white : neutral300
            : (isHovered || isPressed) ?
                colored ? white : neutral300
                : isFocused ?
                    primary600 : primary500;
    // const iconFillColor
    const backgroundColor = disabled ?
        filled ?
            colored ? primary500 : 'transparent'
            : white
        : filled ?
            isPressed ?
                colored ? primary580 : neutral350
                : isHovered || isFocused ?
                    colored ? primary550 : neutral330
                    : colored ? primary500 : 'transparent'
            : isPressed ?
                primary600 : isHovered ?
                    primary500 : isFocused ?
                        white : white;

    const opacity = disabled ?
        .6
        // : filled ?
        //     isPressed ?
        //         .8 : isHovered || isFocused ?
        //             .5 : 1
        : 1;
    const borderColor = disabled ?
        filled ?
            colored ? 'rgba(229, 9, 20, 0.3)' : neutral300
            : 'rgba(229, 9, 20, 0.3)'
        : filled ?
            (isHovered || isFocused || isPressed) ?
                colored ? primary500 : neutral300
                : colored ? primary500 : neutral300
            : isFocused ?
                primary600 : primary500;

    const sizeOptions = {
        small: { maxChars: 28, fontSize: custFontSize ? custFontSize : 16, adjustment: .6 },
        medium: { maxChars: 40, fontSize: custFontSize ? custFontSize : 18, adjustment: .7 },
        large: { maxChars: 60, fontSize: custFontSize ? custFontSize : 20, adjustment: .8 },
    };
    let { maxChars, fontSize, adjustment } = sizeOptions[size] || sizeOptions['small'];
    if (icon || iconName) {
        maxChars = Math.floor(maxChars * adjustment);
    };
    const iconHeight = fontSize + 6;
    const limitedLabel = label.length > maxChars ? label.slice(0, maxChars) + '...' : label;

    const customButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor,
        opacity: opacity,
        borderStyle: 'solid',
        borderColor: borderColor,
        borderRadius: '40px',
        borderWidth: '1px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '12px',
        paddingBottom: '12px',
        width: size === 'large' ? '450px' : size === 'medium' ? '300px' : icon ? '130px' : iconName ? '130px' : '98px',
        cursor: disabled ? "not-allowed" : "pointer",
    };
    const buttonLabelStyle = {
        color: iconLineColor,
        opacity: opacity,
        fontSize: fontSize,
        fontFamily:"IBM Plex Sans",
        fontWeight: '700',
        marginLeft: !icon ? 0 : !iconName ? 0 : iconPosition === 'left' ? '10px' : 0,
        marginRight: !icon ? 0 : !iconName ? 0 : iconPosition === 'right' ? '10px' : 0,
    };

    const handleClick = () => {
        if (!disabled) {
            onClick();
        };
    };

    return (
        <div
            style={customButtonStyle}
            // tabIndex={0}
            onMouseEnter={() => !disabled && !isPressed && setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseDown={() => !disabled && setIsPressed(true)}
            onMouseUp={() => setIsPressed(false) && setIsHovered(false)}
            onFocus={() => !disabled && setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onClick={handleClick}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                {icon ? iconPosition === 'left' &&
                    <div style={{marginRight:'7.5px'}}>
                    icon
                    </div>
                    : null
                }
                {iconName ? iconPosition === 'left' &&
                    <div style={{marginRight:'7.5px'}}>
                    <CustomIcon
                        name={iconName}
                        size={iconHeight}
                        color={iconLineColor}
                        opacity={opacity}
                        strokeWidth='1.5'
                    />
                    </div>
                    : null
                }
                {label ?
                    <div
                        className="buttonMedium"
                        style={buttonLabelStyle}
                    >
                        {limitedLabel}
                    </div>
                    : null
                }
                {icon ? iconPosition === 'left' &&
                    icon
                    : null
                }
                {iconName ? iconPosition === 'right' &&
                    <div style={{marginLeft:'7.5px'}}>
                    <CustomIcon
                        name={iconName}
                        size={iconHeight}
                        color={iconLineColor}
                        opacity={opacity}
                        strokeWidth='1.5'
                    />
                    </div>
                    : null
                }
            </div>
        </div>
    );
};