// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9N0h5awG5rdxDD8CB0UiCAX5Xv58zjIQ",
  authDomain: "revert-281122.firebaseapp.com",
  databaseURL: "https://revert-281122.firebaseio.com",
  projectId: "revert-281122",
  storageBucket: "revert-281122.appspot.com",
  messagingSenderId: "959566270031",
  appId: "1:959566270031:web:e2975b5b74be7310fe243a",
  measurementId: "G-7T06QRVTZL",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
let uid = "";

const functions = getFunctions(app);

export const auth = getAuth(app);
onAuthStateChanged(auth, (user) => {
  if (user) {
    uid = user.uid;
  }
});
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const response = await signInWithPopup(auth, googleProvider);
    const user = response.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     console.log('email login');
//     await signInWithEmailAndPassword(auth, email, password);
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = response.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = async () => {
  const user = auth.currentUser;

  if (user) {
    // If a user is signed in, proceed with the logout
    if (auth) {
      try {
        await signOut(auth).catch((error) => {
          console.error("Sign out error:", error.message);
        });
        console.log("logged out successfully");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }
  } else {
    // No user is signed in
    console.log("No user is currently signed in");
  }
};

const deleteAuthUser = async (uid) => {
  try {
      const deleteUserFunction = httpsCallable(functions, 'deleteAuthUser');
      const result = await deleteUserFunction({ uid });
      // console.log(result.data);
  } catch (error) {
      console.error("Error calling deleteAuthUser:", error.message);
  }
};

export {
  signInWithGoogle,
  // logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  uid,
  deleteAuthUser
};
export { storage };
export default db;
