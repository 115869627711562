import { isObject } from "@mui/x-data-grid/internals";
import React, { useState } from "react";
import IconButton from "../iconButton";
import TextButton from "../textButton";
import { primary500, white, neutral300, black, elevationDark1 } from "../../lib/rebrandColors/colors";
import { useNavigate } from "react-router-dom";

const SideModal = ({
    isOpen,
    onClose,
    buttonArr,
    activeIndex,
    iconArr,
    iconPos,
    fontSize,
    onPressArr,
    size = 'small',
    setActiveIndex,
    setActiveIndexAdmin,
}) => {
    const navigate = useNavigate();
    const modalStyle = {
        position: 'fixed',
        top: 0,
        right: isOpen ? "0px" : '-1000px', /* Hidden off-screen */
        height: '100vh',
        width: '300px',
        backgroundColor: black,
        boxShadow: elevationDark1,
        transition: 'right 0.3s ease',
        zIndex: 1000,
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
    };
    const accountOnPress = () => {
        setActiveIndex(-1);
        setActiveIndexAdmin(-1);
        navigate("/account");
    };
    return (
        <div
            style={modalStyle}
        >
            <div style={{
                alignSelf: 'flex-end'
            }}>
                <IconButton
                    iconName={'cancel-1'}
                    buttonType="standard"
                    onClick={onClose}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {buttonArr &&
                    buttonArr.map((label, i) => {
                        return (
                            <div
                                key={`side${i}`}
                                style={{
                                    borderBottomColor: activeIndex === i ? primary500 : size === 'small' ? neutral300 :
                                        'transparent',
                                    borderBottomWidth: size === 'small' ? '1px' : '4px',
                                    borderBottomStyle: 'solid',
                                    marginBottom: '15px'
                                }}
                            >
                                <TextButton
                                    label={label}
                                    icon={iconArr ? iconArr[i] : null}
                                    iconPosition={iconPos}
                                    fill={activeIndex === i ? size === 'small' ? primary500 : white : neutral300}
                                    onPress={onPressArr[i]}
                                    fontSize={fontSize}
                                />
                            </div>
                        )
                    })
                }
                <div style={{
                    borderBottomColor: activeIndex === -1 ? primary500 : neutral300,
                    borderBottomWidth: size === 'small' ? '1px' : '4px',
                    borderBottomStyle: 'solid',
                }}>
                    <TextButton
                        label={'Account'}
                        // icon={'user-icon'}
                        // iconPosition="left"
                        onPress={accountOnPress}
                        fontSize={fontSize}
                        fill={activeIndex === -1 ? primary500 : neutral300}
                    />
                </div>
            </div>
        </div>
    );
};

export default SideModal;